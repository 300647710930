import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LocationModal from '../Sidebar/LocationModal'
import { Form, Button, Offcanvas, Nav, Tab, Row, Col } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { logout, setCity } from '../../../features/auth/authSlice'
import Logo from '../../images/ScopeHaiLogo-two-color.png'
import UserProfile from '../../images/user-profile.webp'
import {
  FaDigitalTachograph,
  FaCertificate,
  FaBriefcase,
  FaInfo
} from 'react-icons/fa'
import { showLocationModal } from '../../../features/popup/locationModalSlice'
import SearchHistoryOffcanvas from '../Sidebar/SearchHistoryOffcanvas'
import CandidateProfileUpdateModal from '../../Pages/Modals/CandidateProfileUpdateModal'
import GetVerifiedModal from '../../Pages/Modals/GetVerifiedModal'
import ScopeDetailModal from '../../Pages/Modals/ScopeDetailModal'
import SuccessAlert from '../../Pages/Modals/SuccessAlert'

const Header = ({
  logout,
  userData,
  setCity,
  citySelect,
  allCities,
  showLocationModal,
  selectedCity,
  role,
  fullName,
  ID,
  alertMessage,
  alertColor,
  alertVisible
}) => {
  let navigate = useNavigate()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [urlPath, setUrlPath] = useState(useLocation())

  useEffect(() => {
    console.log(urlPath.pathname)
  }, [urlPath])

  // console.log(ID)

  // const handleDropdownCityChange = (e) => {
  //   const selectedCity = allCities.filter ((item) => item.city === e.target.value)
  //   setCity({
  //       'City': selectedCity[0]
  //   })
  //   localStorage.setItem ('selectedCity', JSON.stringify (selectedCity[0]))
  // };
  // useEffect (() => {
  //   if (localStorage.getItem('selectedCity')){
  //       const getCity = JSON.parse(localStorage.getItem('selectedCity'))
  //       setCity({
  //           'City': getCity
  //       })
  //   }
  // }, [])

  const handleUserProfile = () => {
    navigate('/userProfile')
  }

  const handleLogout = e => {
    localStorage.clear()
    window.location.href = '/'
  }

  const navigateTo = URL => {
    navigate(URL)
    // document.getElementById('modalCloseButton').click()
  }

  const selectLocation = () => {
    showLocationModal({ locationModalVisible: true })
  }

  return (
    <div>
      {alertVisible &&
        <div style={{
          position: "fixed",
          bottom: '40px',
          left: "50%",
          transform: "translate(-50%,50%)",
          zIndex: 999
        }}>
          <SuccessAlert color={alertColor} message={alertMessage} ></SuccessAlert>
        </div>
      }

      <LocationModal></LocationModal>
      <CandidateProfileUpdateModal></CandidateProfileUpdateModal>
      <GetVerifiedModal></GetVerifiedModal>
      <ScopeDetailModal></ScopeDetailModal>
      <div class="appHeader bg-white scrolled">
        <div class="left">
          <a
            href="#"
            className="headerButton fill-primary"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarPanel"
            onClick={handleShow}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </a>
        </div>
        <div class="pageTitle row w-100 d-flex justify-content-center p-0">
          <div className="d-flex justify-content-center p-0">
            <a href="#!" className="headerButton p-0">
              <img
                src={Logo}
                className="img-fluid"
                alt="Brand"
                width="220x220"
              />
            </a>
          </div>
        </div>
        <div class="right">
          <a
            href="#"
            class="headerButton toggle-searchbox fill-primary"
            onClick={selectLocation}
          >
            <ion-icon name="locate"></ion-icon>
            <p className="m-0 ms-1 text-primary d-sm-block d-none">
              {' '}
              {selectedCity}
              {/* <Form.Select className='selectLocation'>
                <option value="Mumbai">Mumbai</option>
                <option value="Delhi">Delhi</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Chennai">Chennai</option>
                <option value="Hyderabad">Hyderabad</option>
                {allCities.map((item) => {
                    return <option value={item.city} selected={item.city === citySelect.city}>{item.shahar}</option>
                })}
              </Form.Select> */}
            </p>
          </a>
        </div>
      </div>

      {/* NEW HEADER UI START */}
      <div class="appHeader scrolled border-bottom" style={{ background: '#f5f5f5' }}>
        <div class="left">
          <div className={(role == 'Employer' || role == 'Candidate') ? '' : 'd-flex'}>
            <a
              href="#"
              className={(role == 'Employer' || role == 'Candidate') ? "headerButton fill-primary d-flex d-md-none align-items-center" : "headerButton fill-primary d-flex align-items-center"}
              data-bs-toggle="offcanvas"
              data-bs-target="#sidebarPanel"
              onClick={handleShow}
            >
              <ion-icon name="menu-outline"></ion-icon>
            </a>
            <a href="#!" className={"headerButton p-0 d-flex justify-content-start align-items-center d-none d-md-flex"}>
              <img
                src={Logo}
                className="img-fluid"
                alt="Brand"
                width="220x220"
              />
            </a>

          </div>
        </div>
        <div class="pageTitle row w-100 d-flex justify-content-center p-0">
          {role == 'Employer' && (urlPath.pathname.includes('employer-dashboard') || urlPath.pathname.includes('emp-candidate-list') || urlPath.pathname.includes('jobs') || urlPath.pathname == '/') &&
            <div class="bg-white w-auto align-items-center d-none d-md-flex" style={{ height: '30px', borderRadius: '5px' }}>
              <div class="w-auto d-flex justify-content-center">
                <div class="text-black px-1 d-flex align-items-center cursor-pointer" onClick={() => navigateTo('/employer-dashboard')} style={{ fontSize: '12px', height: '24px', borderRadius: (urlPath.pathname.includes('employer-dashboard') || urlPath.pathname == '/') ? '5px' : '0px', background: (urlPath.pathname.includes('employer-dashboard') || urlPath.pathname == '/') ? '#CDFFD8' : '#ffffff' }}> <ion-icon name="grid-outline" style={{ margin: '0px 3px 3px 0px' }}></ion-icon> Dashboard</div>

                <div class="px-1 d-flex align-items-center cursor-pointer mx-2" style={{ fontSize: '12px', height: '24px', borderRadius: (urlPath.pathname.includes('candidate-list')) ? '5px' : '0px', background: (urlPath.pathname.includes('candidate-list')) ? '#CDFFD8' : '#ffffff' }} onClick={() => navigateTo('/emp-candidate-list')}><ion-icon name="search-outline" style={{ margin: '0px 3px 3px 0px' }}></ion-icon> Search Candidate</div>

                <div class="text-black px-1 d-flex align-items-center cursor-pointer" style={{ fontSize: '12px', height: '24px', borderRadius: (urlPath.pathname.includes('jobs')) ? '5px' : '0px', background: (urlPath.pathname.includes('jobs')) ? '#CDFFD8' : '#ffffff' }} onClick={() => navigateTo('/jobs')}> <ion-icon name="briefcase-outline" style={{ margin: '0px 3px 3px 0px' }}></ion-icon> Hiring <span class="badge bg-success position-static" style={{ marginLeft: '3px', height: '12px', fontSize: '8px' }}>32</span></div>
              </div>
            </div>
          }
          <a href="#!" className="headerButton p-0 d-flex justify-content-center align-items-center d-block d-md-none">
            <img
              src={Logo}
              className="img-fluid"
              alt="Brand"
              width="100%"
            />
          </a>
        </div>
        <div class="right">
          <a
            class="headerButton toggle-searchbox fill-primary"
          >
            <ion-icon name="settings-outline" class="fs-4 text-secondary"></ion-icon>
          </a>
          <a
            href="javaScript:void(0)"
            class="headerButton toggle-searchbox fill-primary"
            onClick={handleLogout}
          >
            <ion-icon name="log-out-outline" class="fs-4 text-secondary"></ion-icon>
          </a>
        </div>
      </div>
      {/* NEW HEADER UI END */}


      <SearchHistoryOffcanvas />
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="profileBox" closeButton>
          <Offcanvas.Title className="d-flex">
            <div class="image-wrapper">
              <img src={UserProfile} alt="User" class="imaged rounded" />
            </div>
            <div className="in">
              {console.log('fullName: ', fullName)}
              <strong>{fullName}</strong>
              <div className="text-muted pt-1">
                {role === 'Candidate' ? (
                  <p
                    className="edit-profile-btn m-0 text-muted"
                    onClick={() => navigateTo('/candidate-profile')}
                  >
                    View Profile
                  </p>
                ) : role === 'Employer' ? (
                  <p
                    className="edit-profile-btn m-0 text-muted"
                    onClick={() => navigateTo('/employer-profile')}
                  >
                    View Profile
                  </p>
                ) : (
                  role
                )}
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="modal-body p-0">
            <ul className="listview flush transparent no-line image-listview">
              {role === 'Candidate' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/certification-and-trainings')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="ribbon"></ion-icon>
                      </div>
                      <div className="in">Certification & Trainings</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/jobs')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Jobs</div>
                    </div>
                  </li>
                  <li className='d-none'>
                    <div
                      onClick={() => navigateTo('/coming-soon')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="id-card"></ion-icon>
                      </div>
                      <div className="in">Build Career</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'Employer' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/employer-dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Employer Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/emp-placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/jobs')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Hirings</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/emp-candidate-list')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="search"></ion-icon>
                      </div>
                      <div className="in">Search Candidate</div>
                    </div>
                  </li>
                  {/* <li>
                    <div
                      onClick={() => navigateTo('/candidate-profile')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="search"></ion-icon>
                      </div>
                      <div className="in">Candidate Profile</div>
                    </div>
                  </li> */}
                </>
              )}
              {role === 'Admin' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/vsp')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Applicants</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/cohort-applicant')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">Cohort Applicants</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/payments')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">payments</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-certifications')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="ribbon"></ion-icon>
                      </div>
                      <div className="in">Certification & Trainings</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-jobs')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Jobs</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-users')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="people"></ion-icon>
                      </div>
                      <div className="in">Users</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/coming-soon')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="stats-chart"></ion-icon>
                      </div>
                      <div className="in">Reports</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/search-url')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">Search url</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/scrapper')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">Scrapper</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'hr' && (
                <>
                  <li>
                    <div onClick={() => navigateTo('/vsp')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Applicants</div>
                    </div>
                  </li>
                  {/* <li>
                    <div

                      onClick={() => navigateTo('/vps-verification')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Verification</div>
                    </div>
                  </li> */}
                  <li>
                    <div
                      onClick={() => navigateTo('/cslr-candidate-list')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="list-outline"></ion-icon>
                      </div>
                      <div className="in">Candidate List</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'Counsellor' && (
                <li>
                  <div
                    onClick={() => navigateTo('/cslr-candidate-list')}
                    className="item"
                  >
                    <div className="icon-box fill-primary">
                      <ion-icon name="list-outline"></ion-icon>
                    </div>
                    <div className="in">Candidate List</div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="sidebar-buttons appBottomMenu bg-primary text-light">
            <Button
              variant="primary"
              onClick={() => navigateTo('/dashboard')}
              className="item"
            >
              <div className="col">
                <ion-icon name="headset-outline"></ion-icon>
                <strong>Support</strong>
              </div>
            </Button>
            <Button variant="primary" className="item" onClick={handleLogout}>
              <div className="col">
                <ion-icon className="m-0" name="log-out-outline"></ion-icon>
                <strong>Logout</strong>
              </div>
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ auth, locationModal, appToast }) => {
  const { userData, citySelect, allCities, role, fullName, ID } = auth
  const { selectedCity } = locationModal
  const { alertMessage, alertColor, alertVisible } = appToast

  return {
    userData,
    citySelect,
    allCities,
    selectedCity,
    role,
    fullName,
    ID,
    alertMessage,
    alertColor,
    alertVisible
  }
}

const mapDispatchToProps = {
  logout,
  setCity,
  showLocationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
