import React from 'react';
// import CsLineIcons from 'cs-line-icons/CsLineIcons';

const ControlsSearch = ({ tableInstance, onChange }) => {
  const {
    state: { globalFilter },
  } = tableInstance;

  const [value, setValue] = React.useState(globalFilter);

  console.log('typeing data :',onChange);
  

  return (
    <>
      <div class="input-group mb-2">
        <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="basic-addon2" value={value || ''} onChange={(e) => { setValue(e.target.value); onChange(e.target.value); }} placeholder="Search for job position" 
         style={{ borderLeft: 'none', borderColor: 'gainsboro', height: '36px' }}
        />
        <div class="input-group-append">
        </div>
      </div>
      {/* <div>
        <span class="input-group-text" id="basic-addon2" style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px' }}>
          {value && value.length > 0 ? (
            <span
              className="search-delete-icon"
              onClick={() => {
                setValue('');
                onChange('');
              }}
            >
              <ion-icon name="close-outline"></ion-icon>
            </span>
          ) : (
            <span className="search-magnifier-icon pe-none">
              <ion-icon name="search-outline"></ion-icon>
            </span>
          )}
        </span>
      </div> */}
    </>
  );
};

export default ControlsSearch;
