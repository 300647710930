import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    verificationModalVisible: false
}

export const VerificationModalSlice = createSlice({
  name: 'verification',
  initialState,
  reducers: {
    showVerificationModal: (state, action) => {
      const {
        verificationModalVisible
      } = action.payload
      state.verificationModalVisible=verificationModalVisible
    },
    hideVerificationModal: (state, action) => {
        state.verificationModalVisible=false
    }
  }
})

export const { showVerificationModal, hideVerificationModal } = VerificationModalSlice.actions;


export default VerificationModalSlice.reducer
