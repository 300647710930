import { Checkbox } from '@mui/material';
import { React, useState, useEffect, useRef } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import CheckStepNumber from '../CheckStepNumber';
import Opportunity from '../Opportunity';
// import { submitFormStep3 } from '../../../features/auth/authSlice'
import { connect } from 'react-redux'

const RegFormStep3 = ({ changeForm, submitFormStep3, submitFormStep2, completeProfileStep }) => {
  console.log('step 3 triggerd opportunites com', submitFormStep3);

  const navigate = useNavigate();
  useEffect(() => {
    const performanceNavigation = performance.getEntriesByType("navigation")[0];
    if (performanceNavigation.type === "reload") {
      navigate('/');
    }
  }, [navigate]);

  const [validated, setValidated] = useState(false);
  const [selectNotification, setSelectNotification] = useState(false);
  console.log('Went to step 3')

  function submitForm(e) {
    e.preventDefault();

    console.log(completeProfileStep)
    const newStep3RegData = {
      'selectNotification': selectNotification,
      'stepNo': 0,
      'completeProfileStep': completeProfileStep
    }
    console.log(selectNotification)
    console.log(newStep3RegData)
    submitFormStep2(newStep3RegData)
  }

  const handleChildData = ((childData) => {
    console.log("Received data from child:", childData);
    console.log("submit form step 2 function log :", submitFormStep3);

    console.log("submit form step 2 is running");

    const newStep2RegData = {
      'hello': 'hello'
    }
    console.log('OLD UI SUBMIT FORM STEP 2 FUNCTION IS RUNNING');

    console.log('submitFormStep3: ', submitFormStep3);

    submitFormStep3(newStep2RegData)


    console.log('OLD UI SUBMIT FORM STEP 2 FUNCTION IS END');
    setValidated(true)

    console.log("submit form step 2 is ended");
  });



  return (
    <Opportunity onSendData={handleChildData} submitFormStep3={submitFormStep3}></Opportunity>
    // <div className="section mb-2 mt-5 full">
    //     <div className="wide-block pt-5 pb-2">
    //         <Form noValidate validated={validated} onSubmit={submitForm}>
    //             <Form.Check
    //                 className='boxed mb-2'
    //                 type="checkbox"
    //                 id="default-checkbox"
    //                 label="Choose if you would like to receive Job/work notifications on Whatsapp/Email."
    //                 onChange={(e) => setSelectNotification(e.target.value)}
    //             />
    //             <Button type="submit" className="btn btn-primary btn-lg btn-block">Done<i className="fa fa-chevron-right" style={{fontSize: "14px", paddingLeft: "5px"}}></i></Button>
    //         </Form>
    //     </div>
    // </div>
  )
}


const mapStateToProps = ({ auth }) => {
  const { userData, role, fullName, ID, submitFormStep1, newUiCandidateOpportunitiesStep } = auth

  return {
    userData,
    role,
    fullName,
    ID,
    submitFormStep1,
    newUiCandidateOpportunitiesStep,

  }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(RegFormStep3)
