import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Form, Dropdown } from 'react-bootstrap';
import { HiOutlineCurrencyRupee } from "react-icons/hi2";
import { FaStar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Table from '../../layout/ServerSide/components/Table';
import TablePagination from '../../layout/ServerSide/components/TablePagination';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import config from '../../../config';
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect, useRowState, useAsyncDebounce } from 'react-table';
import UserProfile from '../../../components/images/jobs/profile.jpg'





const CandidateCard = ({ vsp, year, salary, immediate, term, sendDataToParent }) => {
    console.log('year insite the card comp : ', year, vsp, salary, immediate, term);
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [pageCount, setPageCount] = React.useState(3);
    const [isOpenAddEditModal, setIsOpenAddEditModal] = useState(false);
    // const [term, setTerm] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const [isOpenToJoin, setIsOpenToJoin] = useState(false);
    const [isOpenToSwitch, setIsOpenToSwitch] = useState(false);
    const [isVsp, setIsVsp] = useState(false);
    const [joiningStatus, setJoiningStatus] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [jobLocation, setJobLocation] = useState();
    // const [totalCount, setTotalCount] = useState();
    // const [sortBy, setSoryBy] = useState();

    const value = joiningStatus?.value;
    console.log("value: ", value);



    const title = 'Server Side';

    const columns = React.useMemo(() => {
        return [
            {
                Header: 'ID',
                accessor: 'ID',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'ID', // Assign a unique id
                width: 50, // Optional width for the column header
                Cell: ({ row }) => {
                    const { ID } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {ID}
                        </span>
                    );
                },
            },
            {
                Header: 'Role',
                accessor: 'RoleID',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'RoleID', // Assign a unique id
                width: 110, // Optional width for the column header
                Cell: ({ row }) => {
                    const { RoleID } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {RoleID}
                        </span>
                    );
                },
            },




            //////////////////////////////////////////
            // {
            //   name: 'Remarks',
            //   selector: (row) => (
            //     <Button
            //       variant={row.VspStatus ? 'outline-primary' : 'primary'}
            //       size="sm"
            //       onClick={() => handelOpenModal(row.ID, row.UserId, row.VspStatus)}
            //     // disabled={row.VspStatus}
            //     >
            //       {row.VspStatus ? 'Verified' : 'Pending'}
            //     </Button>
            //   ),
            //   width: '120px',
            //   margin: "20px"
            // },



            {
                Header: 'Name',
                accessor: 'FullName',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'FullName', // Assign a unique id
                width: 110,
                Cell: ({ row }) => {
                    const { FullName, ID } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={() => {
                                navigate(`/candidate-profile-ro?candidateID=${ID}`);
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {FullName}
                        </span>
                    );
                },
            },

            /////////////////////////////////////////////////////////////////////

            // {
            //   Header: 'Name',
            //   accessor: 'FullName',
            //   sortable: true,
            //   headerClassName: 'text-muted text-small text-uppercase text-nowrap',
            //   id: 'FullName', // Assign a unique id
            //   width: 110,
            //   Cell: ({ row }) => {
            //     const { FullName } = row.original
            //     return (
            //       <span
            //         className="list-item-heading body text-wrap cursor-pointer"
            //         onClick={(e) => {
            //           e.preventDefault();
            //         }}
            //         style={{ fontSize: '14px' }}
            //       >
            //         {FullName}
            //       </span>
            //     );
            //   },
            // },
            {
                Header: 'Phone',
                accessor: 'Phone',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'Phone', // Assign a unique id
                width: 160,
                Cell: ({ row }) => {
                    const { Phone } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {Phone}
                        </span>
                    );
                },
            },
            {
                Header: 'Email',
                accessor: 'Email',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'Email', // Assign a unique id
                width: 220,
                Cell: ({ row }) => {
                    const { Email } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {Email}
                        </span>
                    );
                },
            },
            {
                Header: 'Experience Level',
                accessor: 'ExperienceLevel',
                sortable: true,
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'ExperienceLevel', // Assign a unique id
                width: 150,
                Cell: ({ row }) => {
                    const { ExperienceLevel } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {ExperienceLevel}
                        </span>
                    );
                },
            },
            {
                Header: 'Education',
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'Education', // Assign a unique id
                width: 150,
                Cell: ({ row }) => {
                    const { CareerProfile } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {CareerProfile?.education}
                        </span>
                    );
                },
            },
            {
                Header: 'Degree',
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'Degree', // Assign a unique id
                width: 80,
                Cell: ({ row }) => {
                    const { CareerProfile } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {CareerProfile?.degree}
                        </span>
                    );
                },
            },
            {
                Header: 'General Skills',
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'GSkills', // Assign a unique id
                width: 250,
                Cell: ({ row }) => {
                    const { SkillProfile } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ wordBreak: 'break-all', fontSize: '14px' }}
                        >
                            {SkillProfile?.GeneralSkills?.toString()}
                        </span>
                    );
                },
            },
            {
                Header: 'Professional Skills',
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'PSkills', // Assign a unique id
                width: 250,
                Cell: ({ row }) => {
                    const { SkillProfile } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ wordBreak: 'break-all', fontSize: '14px', color: '#333333' }}
                        >
                            {SkillProfile?.ProfessionalSkills?.toString()}
                        </span>
                    );
                },
            },
            {
                Header: 'Certifications',
                headerClassName: 'text-muted text-small text-uppercase text-nowrap',
                id: 'Certificate', // Assign a unique id
                width: 130,
                Cell: ({ row }) => {
                    const { SkillProfile } = row.original
                    return (
                        <span
                            className="list-item-heading body text-wrap cursor-pointer"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                            style={{ fontSize: '14px' }}
                        >
                            {SkillProfile?.Certificate}
                        </span>
                    );
                },
            },
        ];
    }, []);

    const tableInstance = useTable(
        {
            columns,
            data,
            setData,
            isOpenAddEditModal,
            setIsOpenAddEditModal,
            manualPagination: true,
            manualFilters: true,
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount,
            initialState: { pageIndex: 0, sortBy: [{ id: 'ID', desc: false }], hiddenColumns: ['id'] },
        },
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        useRowState
    );
    const {
        state: { pageIndex, pageSize, sortBy }, gotoPage
    } = tableInstance;

    const fetchData = React.useCallback(async () => {
        setShowLoader(true)
        const isVsp = vsp;
        document.body.classList.add('spinner');




        const response = await axios.get(`${config.apiUrl}/user/datafilter`, { params: { term, sortBy: sortBy.length > 0 ? sortBy : [{ id: 'ID', desc: false }], pageSize, pageIndex, value, isVsp, year, salary, immediate } });
        // http://localhost:4444/api/user/datafilter?page=1&pageSize=5&sortOrder=ASC&jstatus=join
        // https://beta.scopehai.com/api/user/datafilter
        console.log('incoming responses :', response)
        setTimeout(() => {
            if (response.status) {
                setShowLoader(false)
            }
            const { items, pageCount: pCount } = response.data;
            setData(items);
            setPageCount(pCount);
            sendDataToParent(response?.data?.totalCount);
            document.body.classList.remove('spinner');
        }, 1000);
    }, [term, sortBy, pageSize, pageIndex, value, vsp, year, salary, immediate]);
    console.log('page count: ', pageCount);


    useEffect(() => {
        fetchData();
    }, [fetchData, vsp]);


    const handleJobLocation = (selectedOptions) => {
        console.log(selectedOptions)
        const location = selectedOptions;
        setJobLocation(location);
        console.log(location);
    };
    const handleJoiningStatus = (selectedOptions) => {
        console.log(selectedOptions)
        const status = selectedOptions;
        setJoiningStatus(status);
        console.log(status.value);
    };

    console.log('DATA : ', data[0]);
    // const map = data.map(candiate, index) => ()

    console.log("handelOpenToVsp value: ", isOpenToSwitch);
    const handelOpenToVsp = () => {
        console.log("hendel open to Vsp triggerd !");
        setIsVsp((prevState) => !prevState);
        console.log("handelOpenToVsp: ", isOpenToSwitch);
    }



    return (
        <>




            {/* <div class="col-12 col-md-4 mb-2">
                <Card className="shadow-none border mb-2">
                    <Card.Body>
                        <div class="row">
                            <div class="col-3 col-md-3">
                                <img src="https://www.electricallicenserenewal.com/app-assets/images/user/12.jpg" className="rounded-circle" alt="" width="100%" />
                            </div>
                            <div className="col-9 col-md-9">
                                <h4 className="fw-bold mb-0 d-flex align-items-center">Riya Kapoor &nbsp;<ion-icon name="checkmark-done-outline" class="text-primary"></ion-icon></h4>
                                <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>Data analyst Intern . 2 yrs</p>
                                <div>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>DQL</span>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Scripting</span>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Integration Tools</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12 mt-1 mb-1 d-flex">
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="location-outline"></ion-icon>&nbsp;Remote</span>
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="calendar-outline"></ion-icon>&nbsp;Immediate</span>
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><HiOutlineCurrencyRupee />&nbsp;₹24k/month</span>
                            </div>
                            <div class="col-6 col-md-6">
                                <h6 className="mb-0">Shortlisted 12 times in last month</h6>
                            </div>
                            <div class="col-6 col-md-6 d-flex justify-content-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm dropdown-toggle ps-1 pe-1" type="button" id="dropdownMenuButton100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: '#f5f5f5' }}>
                                        <ion-icon name="star-outline"></ion-icon> Shortlist&nbsp;&nbsp;<ion-icon name="chevron-down-outline" class="fs-6 me-0"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton100">
                                        <a class="dropdown-item" href="#!">Action</a>
                                        <a class="dropdown-item" href="#!">Another action</a>
                                        <a class="dropdown-item" href="#!">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <div class="col-12 col-md-4 mb-2">
                <Card className="shadow-none border">
                    <Card.Body>
                        <div class="row">
                            <div class="col-3 col-md-3">
                                <img src="https://www.electricallicenserenewal.com/app-assets/images/user/12.jpg" className="rounded-circle" alt="" width="100%" />
                            </div>
                            <div className="col-9 col-md-9">
                                <h4 className="fw-bold mb-0 d-flex align-items-center">Riya Kapoor &nbsp;<ion-icon name="checkmark-done-outline" class="text-primary"></ion-icon></h4>
                                <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>Data analyst Intern . 2 yrs</p>
                                <div>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>DQL</span>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Scripting</span>
                                    <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Integration Tools</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-12 mt-1 mb-1 d-flex">
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="location-outline"></ion-icon>&nbsp;Remote</span>
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="calendar-outline"></ion-icon>&nbsp;Immediate</span>
                                <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><HiOutlineCurrencyRupee />&nbsp;₹24k/month</span>
                            </div>
                            <div class="col-6 col-md-6">
                                <h6 className="mb-0">Shortlisted 12 times in last month</h6>
                            </div>
                            <div class="col-6 col-md-6 d-flex justify-content-end">
                                <div class="dropdown">
                                    <button class="btn btn-sm dropdown-toggle ps-1 pe-1" type="button" id="dropdownMenuButton100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: '#FEF6D8' }}>
                                        <ion-icon name="checkmark-outline"></ion-icon> Shortlisted&nbsp;&nbsp;<ion-icon name="chevron-down-outline" class="fs-6 me-0"></ion-icon>
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton100">
                                        <a class="dropdown-item" href="#!">Action</a>
                                        <a class="dropdown-item" href="#!">Another action</a>
                                        <a class="dropdown-item" href="#!">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div> */}



            {/* ////////////////////////////////////// card loder //////////////////////////////////////////// */}

            {showLoader === true ?
                (<>
                    <div className="row">
                        {Array.from({ length: 9 }).map((_, index) => (
                            <div className="col-12 col-md-4 mb-2" key={index}>
                                <Card className="shadow-none border-none mt-2 mb-2">
                                    <Card.Body className="p-0 placeholder-glow">
                                        <div
                                            className="placeholder w-100"
                                            style={{ height: '110px', borderRadius: '6px' }}
                                        ></div>
                                        <div
                                            className="placeholder w-100 mt-1"
                                            style={{ height: '15px', borderRadius: '50px' }}
                                        ></div>
                                        <div
                                            className="placeholder w-75 mt-1"
                                            style={{ height: '15px', borderRadius: '50px' }}
                                        ></div>
                                    </Card.Body>
                                </Card>
                            </div>
                        ))}
                    </div>
                </>)
                :
                (<>
                    {data.map((candiate, index) => (
                        <div class="col-12 col-md-4 mb-2">
                            <Card className="shadow-none border mb-2 h-100" key={index.ID} onClick={() => {
                                navigate(`/candidate-profile-ro?candidateID=${candiate.ID}`);
                            }}>
                                <Card.Body class="card-body d-flex flex-column">
                                    <div class="col">
                                        <div className="row mb-2" >
                                            <div class="col-3 col-md-3">
                                                {candiate.Image ? (
                                                    <img src={candiate.Image} className="rounded-circle" alt="" width="100%" style={{ aspectRatio: '1', objectFit: 'cover' }} />
                                                ) : (
                                                    <img src={UserProfile} className="rounded-circle" alt="" width="100%" style={{ aspectRatio: '1', objectFit: 'cover' }} />
                                                )}
                                            </div>
                                            <div className="col-9 col-md-9">
                                                <h4 className="fw-bold mb-0 d-flex align-items-center">{candiate.FullName}
                                                    {candiate.HrComments !== null && (<ion-icon name="checkmark-done-outline" class="text-primary" style={{ 'marginLeft': '5px' }}></ion-icon>)}
                                                </h4>
                                                <div>
                                                    {candiate?.Experience && candiate.Experience.length >= 0 ? (
                                                        (() => {
                                                            const latestExperience = [...candiate.Experience].sort((a, b) => b.endYear - a.endYear)[0]
                                                            // console.log('latestExperience', latestExperience);
                                                            const duration =
                                                                latestExperience?.startYear && latestExperience?.endYear
                                                                    ? `${latestExperience?.startYear} - ${latestExperience?.endYear}`
                                                                    : latestExperience?.startYear
                                                                        ? `${latestExperience?.startYear} - Present`
                                                                        : null;
                                                            // console.log('latestExperience.endYear :', latestExperience.endYear);
                                                            // console.log('latestExperience.startYear :', latestExperience.startYear);
                                                            // console.log('EXP IN YEARS ;', duration);
                                                            return (
                                                                <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>
                                                                    {latestExperience?.designation} <br></br>
                                                                    {duration}
                                                                </p>
                                                            );
                                                        })()
                                                    ) : candiate?.Education && candiate.Education.length > 0 ? (
                                                        (() => {
                                                            const latestEducation = [...candiate.Education].sort((a, b) => b.endYear - a.endYear)[0]
                                                            return (
                                                                <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>
                                                                    {latestEducation.qualification} • {latestEducation.institution}
                                                                </p>
                                                            );
                                                        })()
                                                    ) : (
                                                        <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>No details available</p>
                                                    )}
                                                </div>

                                                {/* <p className="mb-0 text-secondary" style={{ fontSize: '11px' }}>Data analyst Intern . 2 yrs</p> */}
                                                <div>
                                                    {candiate.SkillProfile.ProfessionalSkills && candiate.SkillProfile.ProfessionalSkills?.length > 0 && candiate.SkillProfile.ProfessionalSkills?.slice(0, 3).map((skill, index) => (
                                                        <span key={`general-${index}`} className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>
                                                            {skill}
                                                        </span>
                                                    ))}

                                                    {/* Display remaining skills count if there are more than 3 */}
                                                    {candiate.SkillProfile.ProfessionalSkills && candiate.SkillProfile.ProfessionalSkills.length > 3 && (
                                                        <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }} >
                                                            +{candiate.SkillProfile.ProfessionalSkills.length - 3}
                                                        </span>
                                                    )}

                                                    {/* <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>DQL</span>
                                            <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Scripting</span>
                                            <span className="badge border text-black" style={{ marginRight: '5px', fontSize: '11px', height: '20px' }}>Integration Tools</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {candiate.HrComments &&
                                        <div class="row">
                                            <div class="col-12 col-md-12 mt-1 mb-1 d-flex">
                                                {candiate.HrComments?.jobLocation && candiate.HrComments.jobLocation.length > 0 && (
                                                    <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}>
                                                        <ion-icon name="location-outline"></ion-icon>
                                                        {candiate.HrComments.jobLocation}
                                                    </span>
                                                )}
                                                {candiate.HrComments?.Availability && candiate.HrComments?.Availability.length > 0 && (
                                                    <span className='me-2 d-flex align-items-center text-secondary' style={{ fontSize: '11px' }}>
                                                        <ion-icon name="calendar-outline"></ion-icon>&nbsp;
                                                        {candiate.HrComments?.Availability}
                                                    </span>
                                                )}
                                                {candiate.HrComments?.ctc && candiate.HrComments?.ctc.length > 0 && (
                                                    <span className='me-2 d-flex align-items-center text-secondaray' style={{ fontSize: '11px' }}>
                                                        <HiOutlineCurrencyRupee />&nbsp;
                                                        {candiate.HrComments?.ctc}&nbsp;LPA
                                                    </span>
                                                )}
                                                {/* <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="location-outline"></ion-icon>&nbsp;Remote</span>
                                        <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><ion-icon name="calendar-outline"></ion-icon>&nbsp;Immediate</span> */}
                                                {/* <span className="me-2 d-flex align-items-center text-secondary" style={{ fontSize: '11px' }}><HiOutlineCurrencyRupee />&nbsp;₹24k/month</span> */}
                                            </div>
                                        </div>
                                    }
                                    <div class="row mt-auto">
                                        <div class="col-6 col-md-6">
                                            <h6 className="mb-0">Shortlisted 12 times in last month</h6>
                                        </div>
                                        <div class="col-6 col-md-6 d-flex justify-content-end">
                                            <div class="dropdown">
                                                <button class="btn btn-sm dropdown-toggle ps-1 pe-1" type="button" id="dropdownMenuButton100" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ backgroundColor: '#f5f5f5' }}>
                                                    <ion-icon name="star-outline"></ion-icon> Shortlist&nbsp;&nbsp;<ion-icon name="chevron-down-outline" class="fs-6 me-0"></ion-icon>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton100">
                                                    <a class="dropdown-item" href="#!"><FaStar className="me-1" /> Shortlist</a>
                                                    <a class="dropdown-item" href="#!"><ion-icon name="checkmark-done-outline" class="me-1"></ion-icon> Verify</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    ))}
                </>)
            }


            {/* ////////////////////////////////////// card loder //////////////////////////////////////////// */}



            {/* <Col xs="12" className="overflow-scroll custom-parent-pagination-scroll"> */}
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-12">
                    <TablePagination tableInstance={tableInstance} />
                </div>
            </div>
            {/* </Col> */}
        </>
    )
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateCard)
