import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  profileUpdateModalVisible: false,
  profileUpdateType: null,
  userInfo: null,
  education: null,
  experience: null
}

export const ProfileUpdateModalSlice = createSlice({
  name: 'profileUpdate',
  initialState,
  reducers: {
    showProfileUpdateModal: (state, action) => {
      const {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo,
        education,
        experience
      } = action.payload
      console.log('action payload: ', action.payload);
      console.log('action payload education: ', action.payload.experience);

      state.profileUpdateModalVisible = profileUpdateModalVisible
      state.profileUpdateType = profileUpdateType
      state.userInfo = userInfo;
      state.education = education;
      state.experience = experience;
    },
    hideProfileUpdateModal: (state, action) => {
      state.profileUpdateModalVisible = false
      state.profileUpdateType = null
      state.userInfo = null;
    }
  }
})

export const { showProfileUpdateModal, hideProfileUpdateModal } = ProfileUpdateModalSlice.actions;


export default ProfileUpdateModalSlice.reducer
