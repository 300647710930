import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import { connect } from "react-redux";
import { getCompleteProfileusers, paymentGateway, paymentGatewayVerify } from '../../../features/constants/apis';
import { responsiveFontSizes } from '@mui/material';
import PaymentSuccessModal from './PaymentSuccessModal';

const GetVspModal = ({ ID, authToken, show, handleClose, vspStatus }) => {

    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [showPaymentSuccessModal, setShowPaymentSUccessModal] = useState(false);
    const [loader, setLoader] = useState(true);

    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setLoader(true);
    //     }, 50);
    //     return () => clearTimeout(timer);
    // }, [])

    const handleClosePaymentSuccessModal = () => {
        setShowPaymentSUccessModal(false);
    };
    const [loading, setLoading] = useState(true);
    const navigateTo = useNavigate();
    useEffect(() => {
        if (show) {
            getCompleteProfileStatus();
        }
    }, [show, authToken, ID])
    const getCompleteProfileStatus = async () => {
        try {
            setLoading(true);
            const response = await getCompleteProfileusers(authToken, ID);
            console.log(response.data.data)
            if (response.data.data === true) {
                setIsProfileComplete(true);
            } else {
                setIsProfileComplete(false);
                alert(response.data.message);
            }
        } catch (error) {
            console.error("Error fetching profile completion status:", error);
            setIsProfileComplete(false);
        } finally {
            setLoading(false);
        }
    }
    const handleYesClick = () => {
        if (isProfileComplete) {
            handlePayment();
            // phonepePaymentGateway();
        } else {
            alert("Your profile is incomplete. Please complete it before applying for VSP.")
        }
    }
    let amount = 500;
    //PhonePe Payment Gateway function
    const phonepePaymentGateway = async () => {
        try {
            const response = await paymentGateway(authToken, ID, amount);
            if (response.data.status === 201) {
                setShowPaymentSUccessModal(true);
                alert("Successfully applied for VSP");
                handleClose();
            } else {
                alert("Payment failed");
            }
        } catch (error) {
            console.error("Error making payment:", error);
        }
    }

    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const handlePayment = async () => {
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        // Create a new order from the backend
        const response = await paymentGateway(authToken, ID, amount);
        const options = {
            key: 'rzp_live_8l4xyXelQxA8Xi', //Prod Credentials
            // key: 'rzp_test_OME1N5V70ECevu', //Beta Credentials
            amount: amount,
            currency: 'INR',
            name: 'ScopeHai.com',
            description: 'Test Transaction',
            order_id: response.data.orderId,
            handler: async function (response) {
                const paymentData = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    appliedTo: 'VSP',
                    userId: ID,
                    amount: amount
                };
                // Verify the payment on the backend
                const verifyRes = await paymentGatewayVerify(paymentData);

                if (verifyRes.data.success) {
                    setShowPaymentSUccessModal(true);
                    // alert('Payment Successful!');
                    handleClose();
                } else {
                    alert('Payment verification failed. Try again.');
                }
            },
            theme: {
                color: '#378B7A',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
        handleClose();
    };

    return (
        <div className='container'>
            {isProfileComplete ?
                <>
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Get VSP</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {vspStatus === false ? 'VSP Application is in progress.' : vspStatus === true ? '' : 'Apply for VSP with current profile details?'}
                            {/* <h2>Apply for VSP with current profile details?</h2> */}
                        </Modal.Body>
                        <Modal.Footer>
                            {
                                vspStatus === false ? <Button
                                    variant="primary"
                                    onClick='{handleYesClick}'
                                    disabled={loading}
                                >Ok</Button>
                                    :
                                    vspStatus === true ? <Button
                                        variant="primary"
                                        onClick={handleYesClick}
                                        disabled={loading}
                                    ></Button>
                                        :
                                        <Button
                                            variant="primary"
                                            onClick={handleYesClick}
                                            disabled={loading}
                                        >yes</Button>

                            }
                            {
                                vspStatus === false ? '' : vspStatus === true ? '' : <Button variant="secondary" onClick={() => navigateTo('/candidate-profile')}>
                                    No, Let me edit
                                </Button>
                            }

                        </Modal.Footer>
                    </Modal>
                </>
                :
                <>
                    <Modal show={show} onHide={handleClose} centered>
                        <Modal.Header closeButton>

                            <Modal.Title>Complete Your Profile</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h2>Your profile is incomplete. Please complete it before applying for VSP.</h2>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="primary"
                                onClick={() => {
                                    navigateTo('/candidate-profile')
                                    handleClose();
                                }}
                            >
                                Let me edit
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Do it later
                            </Button>
                        </Modal.Footer>
                    </Modal>

                </>
            }
            <PaymentSuccessModal
                show={showPaymentSuccessModal}
                handleClose={handleClosePaymentSuccessModal}
            />
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, ID, authToken } = auth
    return {
        userData,
        role,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(GetVspModal)