/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { Badge, Col, Form, Row } from 'react-bootstrap';
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect, useRowState, useAsyncDebounce } from 'react-table';
import axios from 'axios';
import ButtonsCheckAll from './components/ButtonsCheckAll';
import ControlsPageSize from './components/ControlsPageSize';
import ControlsAdd from './components/ControlsAdd';
import ControlsEdit from './components/ControlsEdit';
import ControlsDelete from './components/ControlsDelete';
import ControlsSearch from './components/ControlsSearch';
import ModalAddEdit from './components/ModalAddEdit';
import Table from './components/Table';
import TablePagination from './components/TablePagination';
import { Button, Image } from 'react-bootstrap';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { serverTable, UpdateAttendanceStatus, GetAllUsers} from '../../../../src/features/constants/apis'
import config from '../../../config'




const ServerSide = ({ authToken, role }) => {
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(false);
  const [isOpenToJoin, setIsOpenToJoin] = useState(false);
  const [isOpenToSwitch, setIsOpenToSwitch] = useState(false);
  const [isVsp, setIsVsp] = useState(false);
  const [joiningStatus, setJoiningStatus] = useState();
  const [expStatus, setExpStatus] = useState();
  const [degree, setDegree] = useState(null);
  const [degreeList, setDegreeList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [jobLocation, setJobLocation] = useState();
  const [attended, setAttended] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  const title = 'Server Side';


  const handelProfile = (ID) => {
    setSelectedUser({ ID: ID });
    navigate(`/candiate-profile-ro?candidateID=${selectedUser?.UserId}`)
  };
  console.log("UserID: ", selectedUser);

  // Function to handle attendance toggle
  const handleAttendanceToggle = async (ID, newStatus) => {
    console.log("Selected User ID: ", ID);
    try {
      const data = await UpdateAttendanceStatus(authToken, ID, role)
      console.log("Attendance Status:", data);
      fetchData();
    } catch (error) {
      console.error("Failed to update attendance status:", error);
    }
  }

  const columns = React.useMemo(() => {
    return [
      {
        Header: 'HR',
        accessor: 'HrAttended',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'HrAttended', // Assign a unique id
        width: 110,
        Cell: ({ row }) => {
          const { HrAttended, ID } = row.original;
          return (
            <>
              {role === 'hr' ? (
                <button
                  type="button"
                  className={`btn btn-${HrAttended ? 'success' : 'primary'} mb-2`}
                  onClick={() => handleAttendanceToggle(ID, HrAttended)}
                >
                  {HrAttended ? 'Attended' : 'Not Attended'}
                </button>
              )
                :
                (
                  <span
                    className="list-item-heading body text-wrap cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {HrAttended ? 'Attended' : 'Not Attended'}
                  </span>
                )}
            </>
          )
        },
      },
      {
        Header: 'Counsellor',
        accessor: 'CslrAttended',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'CslrAttended', // Assign a unique id
        width: 110,
        Cell: ({ row }) => {
          const { CslrAttended, ID } = row.original
          return (
            <>
              {role === 'Counsellor' ? (
                <button
                  type="button"
                  className={`btn btn-${CslrAttended ? 'success' : 'primary'} mb-2`}
                  onClick={() => handleAttendanceToggle(ID, CslrAttended)}
                >
                  {CslrAttended ? 'Attended' : 'Not Attended'}
                </button>
              )
                :
                (
                  <span
                    className="list-item-heading body text-wrap cursor-pointer"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    style={{ fontSize: '14px' }}
                  >
                    {CslrAttended ? 'Attended' : 'Not Attended'}
                  </span>
                )}
            </>
          );
        },
      },
      {
        Header: 'ID',
        accessor: 'ID',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'ID', // Assign a unique id
        width: 50, // Optional width for the column header
        Cell: ({ row }) => {
          const { ID } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {ID}
            </span>
          );
        },
      },
      {
        Header: 'Role',
        accessor: 'RoleID',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'RoleID', // Assign a unique id
        width: 110, // Optional width for the column header
        Cell: ({ row }) => {
          const { RoleID } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {RoleID}
            </span>
          );
        },
      },




      //////////////////////////////////////////
      // {
      //   name: 'Remarks',
      //   selector: (row) => (
      //     <Button
      //       variant={row.VspStatus ? 'outline-primary' : 'primary'}
      //       size="sm"
      //       onClick={() => handelOpenModal(row.ID, row.UserId, row.VspStatus)}
      //     // disabled={row.VspStatus}
      //     >
      //       {row.VspStatus ? 'Verified' : 'Pending'}
      //     </Button>
      //   ),
      //   width: '120px',
      //   margin: "20px"
      // },



      {
        Header: 'Name',
        accessor: 'FullName',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'FullName', // Assign a unique id
        width: 110,
        Cell: ({ row }) => {
          const { FullName, ID } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={() => {
                navigate(`/candidate-profile-ro?candidateID=${ID}`);
              }}
              style={{ fontSize: '14px' }}
            >
              {FullName}
            </span>
          );
        },
      },

      /////////////////////////////////////////////////////////////////////

      // {
      //   Header: 'Name',
      //   accessor: 'FullName',
      //   sortable: true,
      //   headerClassName: 'text-muted text-small text-uppercase text-nowrap',
      //   id: 'FullName', // Assign a unique id
      //   width: 110,
      //   Cell: ({ row }) => {
      //     const { FullName } = row.original
      //     return (
      //       <span
      //         className="list-item-heading body text-wrap cursor-pointer"
      //         onClick={(e) => {
      //           e.preventDefault();
      //         }}
      //         style={{ fontSize: '14px' }}
      //       >
      //         {FullName}
      //       </span>
      //     );
      //   },
      // },
      {
        Header: 'Phone',
        accessor: 'Phone',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'Phone', // Assign a unique id
        width: 160,
        Cell: ({ row }) => {
          const { Phone } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {Phone}
            </span>
          );
        },
      },
      {
        Header: 'Email',
        accessor: 'Email',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'Email', // Assign a unique id
        width: 220,
        Cell: ({ row }) => {
          const { Email } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {Email}
            </span>
          );
        },
      },
      {
        Header: 'Experience Level',
        accessor: 'ExperienceLevel',
        sortable: true,
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'ExperienceLevel', // Assign a unique id
        width: 150,
        Cell: ({ row }) => {
          const { ExperienceLevel } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {ExperienceLevel}
            </span>
          );
        },
      },
      {
        Header: 'Education',
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'Education', // Assign a unique id
        width: 150,
        Cell: ({ row }) => {
          const { CareerProfile } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {CareerProfile?.education}
            </span>
          );
        },
      },
      {
        Header: 'Degree',
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'Degree', // Assign a unique id
        width: 80,
        Cell: ({ row }) => {
          const { CareerProfile } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {CareerProfile?.degree}
            </span>
          );
        },
      },
      {
        Header: 'General Skills',
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'GSkills', // Assign a unique id
        width: 250,
        Cell: ({ row }) => {
          const { SkillProfile } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ wordBreak: 'break-all', fontSize: '14px' }}
            >
              {SkillProfile?.GeneralSkills?.toString()}
            </span>
          );
        },
      },
      {
        Header: 'Professional Skills',
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'PSkills', // Assign a unique id
        width: 250,
        Cell: ({ row }) => {
          const { SkillProfile } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ wordBreak: 'break-all', fontSize: '14px', color: '#333333' }}
            >
              {SkillProfile?.ProfessionalSkills?.toString()}
            </span>
          );
        },
      },
      {
        Header: 'Certifications',
        headerClassName: 'text-muted text-small text-uppercase text-nowrap',
        id: 'Certificate', // Assign a unique id
        width: 130,
        Cell: ({ row }) => {
          const { SkillProfile } = row.original
          return (
            <span
              className="list-item-heading body text-wrap cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
              }}
              style={{ fontSize: '14px' }}
            >
              {SkillProfile?.Certificate}
            </span>
          );
        },
      },
    ];
  }, []);

  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = React.useState(3);
  const [isOpenAddEditModal, setIsOpenAddEditModal] = useState(false);
  const [term, setTerm] = useState('');

  const tableInstance = useTable(
    {
      columns,
      data,
      setData,
      isOpenAddEditModal,
      setIsOpenAddEditModal,
      manualPagination: true,
      manualFilters: true,
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount,
      initialState: { pageIndex: 0, sortBy: [{ id: 'ID', desc: true }], hiddenColumns: ['id'] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useRowState
  );
  const {
    state: { pageIndex, pageSize, sortBy },
  } = tableInstance;


  // urls
  // https://beta.scopehai.com/api/user/datafilter
  // http://localhost:4444/api/api/user/datafilter


  useEffect(()=>{
    const fetchData = async () => {
        const data = await GetAllUsers(authToken);
        setAllUsers(data.data);
        console.log(data.data)
    }
    fetchData();
}, [authToken])





  const fetchData = React.useCallback(async () => {
    setShowLoader(true)
    document.body.classList.add('spinner');

    const response = await axios.get(config.apiUrl + '/user/datafilter', { params: { term, sortBy: sortBy.length > 0 ? sortBy : [{ id: 'ID', desc: true }], pageSize, pageIndex, value, isVsp ,expStatus, degree } });
    // http://localhost:4444/api/user/datafilter?page=1&pageSize=5&sortOrder=ASC&jstatus=join

    const Degree = await axios.get(config.apiUrl + '/eduDegrees')
    console.log('degree : ',Degree.data)
    setDegreeList(Degree?.data)
    console.log(response) 

    setTimeout(() => {
      if (response.status) {
        setShowLoader(false)
      }
      const { items, pageCount: pCount } = response.data;
      setData(items);
      setPageCount(pCount);
      document.body.classList.remove('spinner');
    }, 1000);
  }, [sortBy, pageIndex, pageSize, term, isOpenToJoin, isOpenToSwitch, isVsp, joiningStatus, expStatus, degree ]);

  // const addItem = React.useCallback(
  //   async ({ item }) => {
  //     document.body.classList.add('spinner');
  //     const response = await axios.post(`${SERVICE_URL}/datatable`, { sortBy, pageSize, pageIndex, item });
  //     setTimeout(() => {
  //       const { items, pageCount: pCount } = response.data;
  //       setData(items);
  //       setPageCount(pCount);
  //       document.body.classList.remove('spinner');
  //     }, 1000);
  //   },
  //   [sortBy, pageIndex, pageSize]
  // );

  // const editItem = React.useCallback(
  //   async ({ item }) => {
  //     document.body.classList.add('spinner');
  //     const response = await axios.put(`${SERVICE_URL}/datatable`, { sortBy, pageSize, pageIndex, item });
  //     setTimeout(() => {
  //       const { items, pageCount: pCount } = response.data;
  //       setData(items);
  //       setPageCount(pCount);
  //       document.body.classList.remove('spinner');
  //     }, 1000);
  //   },
  //   [sortBy, pageIndex, pageSize]
  // );

  // const deleteItems = React.useCallback(
  //   async ({ ids }) => {
  //     document.body.classList.add('spinner');
  //     const response = await axios.delete(`${SERVICE_URL}/datatable`, { sortBy, pageSize, pageIndex, ids });
  //     setTimeout(() => {
  //       const { items, pageCount: pCount } = response.data;
  //       setData(items);
  //       setPageCount(pCount);
  //       document.body.classList.remove('spinner');
  //     }, 1000);
  //   },
  //   [sortBy, pageIndex, pageSize]
  // );

  const searchItem = useAsyncDebounce((val) => {
    setTerm(val || undefined);
  }, 200);

  useEffect(() => {
    fetchData();
  }, [sortBy, fetchData, pageIndex, pageSize, term, degree, expStatus ]);


  const handleJobLocation = (selectedOptions) => {
    console.log(selectedOptions)
    const location = selectedOptions;
    setJobLocation(location);
    console.log(location);
  };
  const handleJoiningStatus = (selectedOptions) => {
    console.log(selectedOptions)
    const status = selectedOptions;
    setJoiningStatus(status);
    console.log(status.value);
  };

  const handleExp = (selectedOptions) => {
    console.log(selectedOptions)
    const status = selectedOptions;
    setExpStatus(status.value);
    console.log(status.value);
  };
  const handelDegree = (selectedOptions) => {
    console.log(selectedOptions)
    const status = selectedOptions;
    setDegree(status.label);
    console.log(status.label);
  };
  
  const value = joiningStatus?.value;
  console.log("value: ", value);


  const handelOpenToJoin = () => {
    setIsOpenToJoin((prevState) => !prevState);
    setIsOpenToSwitch(false);
  }

  const handelOpenToSwitch = () => {
    setIsOpenToSwitch((prevState) => !prevState);
    setIsOpenToJoin(false);
  }

  console.log("handelOpenToVsp value: ", isOpenToSwitch);
  const handelOpenToVsp = () => {
    console.log("hendel open to Vsp triggerd !");
    setIsVsp((prevState) => !prevState);
    console.log("handelOpenToVsp: ", isOpenToSwitch);
  }

  
  console.log('degreeList :',degreeList)
  return (
    <div class="position-relative">
      {showLoader &&
        <div class="d-flex justify-content-center align-items-center" style={{ position: 'absolute', top: 88, right: 0, left: 0, bottom: 80, background: '#33333350', zIndex: 999 }}>
          <div class="loader">
          </div>
        </div>
      }

      <Row>
        <Col>
          <div className="empbtn d-flex flex-wrap mb-2">
            {/* Job Location Section */}
            <Form.Group className="d-flex flex-column flex-md-row align-items-center me-2">
              <Form.Label className="me-2 mb-1 mb-md-0">Job Location:</Form.Label>
              <Select
                required
                onChange={handleJobLocation}
                value={jobLocation}
                name="skills"
                options={[
                  { value: "Anywhere", label: "Anywhere" },
                  { value: "Pune", label: "Pune" },
                  { value: "Satara", label: "Satara" },
                  { value: "Nashik", label: "Nashik" },
                  { value: "Mumbai", label: "Mumbai" },
                ]}
                classNamePrefix="select"
                placeholder="Location"
              />
            </Form.Group>

            {/* Ready To Section */}
            <Form.Group className="d-flex flex-column flex-md-row align-items-center ms-2 mt-md-0">
              <Form.Label className="me-2 mb-1 mb-md-0">Ready To:</Form.Label>
              <Select
                required
                onChange={handleJoiningStatus}
                value={joiningStatus}
                name="skills"
                options={[
                  { value: "both", label: "Both" },
                  { value: "join", label: "Join" },
                  { value: "switch", label: "Switch" },
                  { value: "off", label: "Off" },
                ]}
                classNamePrefix="select"
                placeholder="Select"
              />
            </Form.Group>

            {/* Experience or fresher */}
            <Form.Group className="d-flex flex-column flex-md-row align-items-start mt-md-0" style={{minWidth:"10rem"}}>
              <Form.Label className="me-2 mb-1 mb-md-0">Exp Level:</Form.Label>
              <Select
                required
                onChange={handleExp}
                value={{value:expStatus , label:expStatus}}
                name="Exp Status"
                options={[
                  { value: "All", label: "All" },
                  { value: "Experienced", label: "Experienced" },
                  { value: "Fresher", label: "Fresher" },
                ]}
                classNamePrefix="select"
                placeholder="Select"
              />
            </Form.Group>

            {/* degree */}
            <Form.Group className="d-flex flex-column flex-md-row align-items-start ms-2 mt-md-0" style={{minWidth:'10rem'}}>
              <Form.Label className="me-2 mb-1 mb-md-0">Degree:</Form.Label>
              <Select
                required
                onChange={handelDegree}
                value={{value:degree, label:degree} }
                name="Degree"

                options={[
                  { value: 'All', label: 'All' }, // Static option to clear filter
                  ...degreeList?.map((option) => ({ value: option?.ID, label: option?.Education }))
                ]}

                classNamePrefix="select"
                placeholder="Select"
              />
            </Form.Group>
                
          </div>
        </Col>
        <Col sm="12" md="5" lg="3" xxl="4">
          <div className="d-inline-block float-md-start mb-md-0 search-input-container">
            <ControlsSearch tableInstance={tableInstance} onChange={searchItem} />
          </div>
        </Col>
      </Row>

      <row>
        <Col>
          <div className='empbtn d-flex mb-2'>
            <Button
              className={isVsp ? 'emp-inner-btn add-btn btn-primary me-2' : 'emp-inner-btn add-btn btn-outline-primary me-2'} variant="outline-primary" onClick={handelOpenToVsp}> ✔ {isVsp ? 'Cancel Verified' : 'Verified'}</Button>

            <Button className='emp-inner-btn add-btn btn-outline-primary me-2' variant="outline-primary" onClick="{handleToggleAccordion}"><span className='' style={{ height: "19px" }}><ion-icon name="toggle-outline"></ion-icon></span>All Filters</Button>

            <Col>
            <lable>Total Candidate Count : <b style={{fontWeight:'800'}}>{allUsers.length}</b></lable>
              <div className="d-inline-block float-end">
                <ControlsPageSize tableInstance={tableInstance} />
              </div>
            </Col>
          </div>


        </Col>




      </row>
      <Row>
        <Col>

          <div>
            {/* <Row className="mb-3">
              <Col sm="12" md="7" lg="9" xxl="8" className="text-end">
                <div className="d-inline-block">
                  <ControlsPageSize tableInstance={tableInstance} />
                </div>
              </Col>
            </Row> */}
            <Row>
              <Col xs="12" className="w-100 overflow-auto">
                <Table className="react-table rows" tableInstance={tableInstance} />
              </Col>
              <Col xs="12" className="overflow-scroll custom-parent-pagination-scroll">
                <TablePagination tableInstance={tableInstance} />
              </Col>
            </Row>
          </div>
          {/* <ModalAddEdit tableInstance={tableInstance} addItem={addItem} editItem={editItem} /> */}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authToken, role } = auth
  return { authToken, role }
}

export default connect(mapStateToProps)(ServerSide);
