import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { IoMdRedo } from 'react-icons/io';
import { hideVerificationModal } from '../../../features/popup/VerificationModalSlice';
import { ChevronRight } from 'lucide-react';
import { paymentGateway, paymentGatewayVerify } from '../../../features/constants/apis';

const GetVerifiedModal = ({
    verificationModalVisible,
    hideVerificationModal,
    authToken,
    ID,
    progressPercentage
}) => {
    const [isProfileComplete, setIsProfileComplete] = useState(false);
    const [disableButton, setDisableButton] = useState(false)
    const [showPaymentSuccessModal, setShowPaymentSUccessModal] = useState(false);

    let amount = 500;
    useEffect(() => {
        console.log(progressPercentage)
        if (verificationModalVisible && progressPercentage) {
            if (progressPercentage >= 60) {
                setIsProfileComplete(true)
            }
        }
    }, [verificationModalVisible, progressPercentage])
    const handleYesClick = () => {
        if (isProfileComplete) {
            handlePayment();
            // phonepePaymentGateway();
        } else {
            alert("Your profile is incomplete. Please complete it before applying for VSP.")
        }
    }
    const handlePayment = async () => {
        setDisableButton(true)
        const res = await loadRazorpayScript();

        if (!res) {
            alert('Razorpay SDK failed to load. Are you online?');
            return;
        }

        // Create a new order from the backend
        const response = await paymentGateway(authToken, ID, amount);
        const options = {
            key: 'rzp_live_8l4xyXelQxA8Xi', //Prod Credentials
            // key: 'rzp_test_OME1N5V70ECevu', //Beta Credentials
            amount: amount,
            currency: 'INR',
            name: 'ScopeHai.com',
            description: 'Test Transaction',
            order_id: response.data.orderId,
            handler: async function (response) {
                const paymentData = {
                    razorpay_order_id: response.razorpay_order_id,
                    razorpay_payment_id: response.razorpay_payment_id,
                    razorpay_signature: response.razorpay_signature,
                    appliedTo: 'VSP',
                    userId: ID,
                    amount: amount
                };
                // Verify the payment on the backend
                const verifyRes = await paymentGatewayVerify(paymentData);

                if (verifyRes.data.success) {
                    setShowPaymentSUccessModal(true);
                    // alert('Payment Successful!');
                    setDisableButton(false)
                    hideVerificationModal();
                } else {
                    alert('Payment verification failed. Try again.');
                }
            },
            theme: {
                color: '#378B7A',
            },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
        // handleClose();
        setDisableButton(false)
        hideVerificationModal();
    };
    const loadRazorpayScript = () => {
        return new Promise((resolve) => {
            const script = document.createElement('script');
            script.src = 'https://checkout.razorpay.com/v1/checkout.js';
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };
    return (
        <div>
            <Modal show={verificationModalVisible} centered backdrop="static" onHide={() => { hideVerificationModal() }}>
                <Modal.Body>
                    <div class="d-flex justify-content-between align-items-center mb-2">
                        <h3 class="mb-0 fs-5 d-flex align-items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" fill="#2563EB" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                            <span class="ms-1">Get Verified</span>
                        </h3>
                        <span class="fs-3 cursor-pointer" onClick={() => { hideVerificationModal() }}><ion-icon name="close-outline"></ion-icon></span>
                    </div>
                    <div class="p-2 mb-2" style={{ background: '#FEF6D8', borderRadius: '5px' }}>
                        {isProfileComplete ?
                            <h4 class="fw-bold">How verification works?</h4>
                            :
                            <h3 class="mb-0 fw-bold text-black text-center">Your profile is incomplete, <br />Complete your profile to continue..</h3>
                        }
                        {isProfileComplete &&
                            <div class='w-100 d-flex justify-content-between mt-4'>
                                <div class="d-flex flex-column align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#45a994" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-calendar-fold"><path d="M8 2v4" /><path d="M16 2v4" /><path d="M21 17V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h11Z" /><path d="M3 10h18" /><path d="M15 22v-4a2 2 0 0 1 2-2h4" /></svg>
                                    <h5 class="text-black text-center mt-1">Book a call with ScopeHai HR</h5>
                                </div>
                                <div class="d-flex align-items-center" style={{ marginBottom: '30px' }}>
                                    <IoMdRedo size={30} />
                                </div>
                                <div class="d-flex flex-column align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#45a994" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-laptop"><path d="M20 16V7a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v9m16 0H4m16 0 1.28 2.55a1 1 0 0 1-.9 1.45H3.62a1 1 0 0 1-.9-1.45L4 16" /></svg>
                                    <h5 class="text-black text-center mt-1">Initial screening and interview</h5>
                                </div>
                                <div class="d-flex align-items-center" style={{ marginBottom: '30px' }}>
                                    <IoMdRedo size={30} />
                                </div>
                                <div class="d-flex flex-column align-items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#45a994" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                                    <h5 class="text-black text-center mt-1">Verification done, increased visibility!</h5>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="d-flex justify-content-end allign-items-center">
                        {isProfileComplete &&
                            <>
                                <button class="btn btn-primary" onClick={() => { handleYesClick() }} disabled={disableButton}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>&nbsp;
                                    Apply for Verification
                                </button>
                            </>
                        }
                        {!isProfileComplete &&
                            <button class="btn btn-outline-primary" onClick={() => { hideVerificationModal() }}>
                                Complete Profile&nbsp;<ChevronRight size={18} />
                            </button>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

const mapStateToProps = ({ verification, auth, data }) => {
    const { verificationModalVisible } = verification
    const { authToken, ID } = auth
    const { progressPercentage } = data
    return {
        verificationModalVisible,
        authToken,
        ID,
        progressPercentage
    }
}

const mapDispatchToProps = {
    hideVerificationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(GetVerifiedModal);