import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    progressPercentage: 0,
    progressRemainingPercent: 0
}

export const DataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setProfileGraphCount: (state, action) => {
      const {
        progressPercentage,
        progressRemainingPercent
      } = action.payload
      state.progressPercentage = progressPercentage
      state.progressRemainingPercent = progressRemainingPercent
    }
  }
})

export const { setProfileGraphCount } = DataSlice.actions;


export default DataSlice.reducer
