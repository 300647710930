import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    searchHistoryOffcanvasVisible: false
}

export const offcanvasSlice = createSlice({
  name: 'offcanvas',
  initialState,
  reducers: {
    showSearchHistoryOffcanvas: (state, action) => {
      const {
        searchHistoryOffcanvasVisible
      } = action.payload
      state.searchHistoryOffcanvasVisible=searchHistoryOffcanvasVisible
    },
    hideSearchHistoryOffcanvas: (state, action) => {
        state.searchHistoryOffcanvasVisible=false
    }
  }
})

export const { showSearchHistoryOffcanvas, hideSearchHistoryOffcanvas } = offcanvasSlice.actions;


export default offcanvasSlice.reducer
