import React, { useEffect, useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getAdminDashboardData } from '../../features/constants/apis';
// import UserChart from './Charts/lineChartEX';

function HrDashboard({ role, fullName, authToken, ID, loginCount }) {

    const [dashboardData, setDashboardData] = useState([]);

    const handleDateSelection = async (startDate, endDate) => {
        const data = await getAdminDashboardData(authToken, startDate, endDate);
        setDashboardData(data.data)
        console.log(data.data, startDate, endDate)
        console.log(authToken)
    }

    return (
        <div id="appCapsule" className='Dashboard'>
            {/* Dashboard Count View Start */}
            <div className='section full py-4'>
                <div className='container'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className="row">
                            <Nav variant="pills">
                                <div className="col-lg-3 col-6 mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='db-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="people-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.totalUsers && dashboardData?.Users?.totalUsers}</h5>
                                                        <h6 className="card-subtitle">Total Users</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                <div className="col-lg-3 col-6 mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='db-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="ribbon-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Certifications?.totalCertifications}</h5>
                                                        <h6 className="card-subtitle">Certifications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                <div className="col-lg-3 col-6 mb-2">
                                    <Nav.Item>
                                        <Nav.Link eventKey="third">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='db-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="briefcase-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Jobs?.totalJobs}</h5>
                                                        <h6 className="card-subtitle">Jobs</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                                {role === 'Admin' &&
                                    <div className="col-lg-3 col-6 mb-2">
                                        <Nav.Item>
                                            <Nav.Link eventKey="fourth">
                                                <div className="card">
                                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                        <div className='db-bg-icon order-sm-1'>
                                                            <ion-icon size="large" name="albums-outline"></ion-icon>
                                                        </div>
                                                        <div className='order-sm-0'>
                                                            <h5 className="card-title">{dashboardData?.PlacementDrives?.totalPlacementDrives}</h5>
                                                            <h6 className="card-subtitle">Placement Drives</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </div>
                                }
                            </Nav>
                        </div><hr />
                        <Tab.Content className='mt-3'>
                            <Tab.Pane eventKey="first">
                                <div className='row'>
                                    <div className='col-12 col-sm-9 col-lg-9 pe-2'>
                                        <div className='card'>
                                            <div className="card-body graphHeight">
                                                User's Graph
                                                {   /* <UserChart handleDateSelection={handleDateSelection} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-3 col-lg-3 ps-lg-0 pe-2'>
                                        <div class="profile-stats flex-column gap-3">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.registeredUsers}</h5>
                                                        <h6 className="card-subtitle">Registered Users</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.firstLogins}</h5>
                                                        <h6 className="card-subtitle">No. of 1st Logins</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.repeatLogins}</h5>
                                                        <h6 className="card-subtitle">No. of Repeat Logins</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.milestones}</h5>
                                                        <h6 className="card-subtitle">Milestones</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Users?.profileCompletions}</h5>
                                                        <h6 className="card-subtitle">Profiles Completed</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <div className='row'>
                                    <div className='col-12 col-sm-9 col-lg-9 pe-2'>
                                        <div className='card'>
                                            <div className="card-body graphHeight">
                                                Certification's Graph
                                                {/* <UserChart /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-3 col-lg-3 ps-lg-0 pe-2'>
                                        <div class="profile-stats flex-column gap-3">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Certifications?.totalCertApplications}</h5>
                                                        <h6 className="card-subtitle">Total Certification Applications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Certifications?.activeCertifications}</h5>
                                                        <h6 className="card-subtitle">Active Certifications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Certifications?.uniqueCertApplicants}</h5>
                                                        <h6 className="card-subtitle">Total Users Applied</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <div className='row'>
                                    <div className='col-12 col-sm-9 col-lg-9 pe-2'>
                                        <div className='card'>
                                            <div className="card-body graphHeight">
                                                Job's Graph
                                                {/* <UserChart /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-3 col-lg-3 ps-lg-0 pe-2'>
                                        <div class="profile-stats flex-column gap-3">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Jobs?.totalJobApplications}</h5>
                                                        <h6 className="card-subtitle">Total Job Applications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Jobs?.activeJobs}</h5>
                                                        <h6 className="card-subtitle">Active Jobs</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.Jobs?.uniqueJobApplicants}</h5>
                                                        <h6 className="card-subtitle">Total Users Applied</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="fourth">
                                <div className='row'>
                                    <div className='col-12 col-sm-9 col-lg-9 pe-2'>
                                        <div className='card'>
                                            <div className="card-body graphHeight">
                                                Placement Drive's Graph
                                                {/* <UserChart /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-sm-3 col-lg-3 ps-lg-0 pe-2'>
                                        <div class="profile-stats flex-column gap-3">
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.PlacementDrives?.activePlacementDrives}</h5>
                                                        <h6 className="card-subtitle">Active Placement Drives</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.PlacementDrives?.totalPDJobs}</h5>
                                                        <h6 className="card-subtitle">Total Placement Drive Jobs</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.PlacementDrives?.activePDJobs}</h5>
                                                        <h6 className="card-subtitle">Active Placement Drive Jobs</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.PlacementDrives?.totalPDApplications}</h5>
                                                        <h6 className="card-subtitle">Total Placement Drive Applications</h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                    <div className='admin-bg-icon order-sm-1'>
                                                        <ion-icon size="large" name="checkmark-circle-outline"></ion-icon>
                                                    </div>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">{dashboardData?.PlacementDrives?.uniquePDApplicants}</h5>
                                                        <h6 className="card-subtitle">Total Users Applied</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
            {/* Dashboard Count View End */}
            {/* User Profile Start */}
            {/* <div className="section full mt-2 mb-2 pt-4 Dashboard">
                <div className='container'>
                    <div className="row">
                        <div className="col-lg-12 col-12 mb-2">
                            <div className='d-flex align-items-center justify-content-between gap-3'>
                                <h1 className="title">Hi, {fullName}</h1>
                            </div>
                            <h2 className='sub-title'>Welcome to your Admin Dashboard !</h2>
                            <p>Here is how your profile is performing on Scopehai Talent platform.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            {/* User Profile End */}
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken, loginCount } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken,
        loginCount
    }
}

export default connect(mapStateToProps)(HrDashboard)