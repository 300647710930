import React from 'react'
import graphAnalysis from '../images/graph-analysis.svg';

const SideValiDidi = () => {
    return (
        <div 
            style={{
                height: '100%',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: `radial-gradient(47.17% 126.72% at 55.99% 38.29%, rgba(205, 255, 217, 0.2) 0%, rgba(26, 53, 96, 0.2) 100%),linear-gradient(181deg, rgba(205, 255, 217, 0.2) 20%, rgba(30, 124, 109, 0.2) 96%),var(--accent, rgba(198, 232, 223, 1))`,
                borderRadius: '1.5rem',
            }}
        >
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                <div>
                    <img src={graphAnalysis} style={{ height: '20rem' }} ></img>
                </div>
            </div>
        </div>
    )
}

export default SideValiDidi
