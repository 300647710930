import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Form } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import moment from 'moment';
import { connect } from 'react-redux';
import EmpPlacementDrives from './EmpPlacementDrives';
import { createJobApplication, fetchCertificationCards, fetchPlacementDrives, GetAllApplicationsOfDrive, getAllCourseApplications, getAllFakeJobs, GetCandidateByID, GetVspStatusByID, UpdateUserProfile } from '../../features/constants/apis';
import CertificationCardComp from './Cards/CertificationCardComp';
import PlacementDriveCard from './Cards/PlacementDriveCard';
import JobListCard from './JobListCard';
import GetVspModal from './Modals/GetVspModal';
import { Search, Users, UserCheck, User, WandSparkles, ArrowUpRight, Mail, ChevronRight } from 'lucide-react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { showVerificationModal } from '../../features/popup/VerificationModalSlice';
import { showScopeDetailModal } from '../../features/popup/ScopeDetailModalSlice';
import { setProfileGraphCount } from '../../features/data/DataSlice';
import SampleCertificate from '../images/icon/certificate.png';
import { ToastContainer, toast } from 'react-toastify';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

function Dashboard({ userData, role, fullName, authToken, ID, showVerificationModal, showScopeDetailModal, progressPercentage, progressRemainingPercentage, setProfileGraphCount, scopeDetailModalVisible }) {

    const [allJobs, setAllJobs] = useState([]);
    const [allFakeJobs, setAllFakeJobs] = useState([]);
    const [placementDrives, setPlacementDrives] = useState([]);
    const [jobApplicationsCount, setJobApplicationsCount] = useState(0);
    const [candidiateJobApplicationsCount, setCandidateJobApplicationsCount] = useState(0);
    const [certificationCards, setCertificationCards] = useState([]);
    const [courseApplications, setCourseApplications] = useState([]);
    const [showGetVspModal, setShowGetVspModal] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [openToStatus, setOpenToStatus] = useState();
    const [progressCount, setProgressCount] = useState();
    const [userVspStatus, setUserVspStatus] = useState();
    const [openToJoin, setOpenToJoin] = useState(Boolean);
    const [jobRoles, setJobRoles] = useState([]);
    const [profileProgressCount, setProfileProgressCount] = useState(0)
    const [profileProgressCountRem, setProfileProgressCountRem] = useState(0)
    const [applyButtonDisable, setApplyButtonDisable] = useState(null)
    const [tempDisabledButtonArr, setTempDisabledButtonArr] = useState([])

    const handleCloseGetVspModal = () => {
        setShowGetVspModal(false);
    };
    console.log("user Info: ", userInfo);
    useEffect(() => {
        const fetchData = async () => {
            console.log("useEffect fun called for get by ID and VSP: ")
            const data = await GetCandidateByID(ID, authToken);
            // console.log("user info :", data.data);
            setUserInfo(data.data);
            setOpenToStatus(data.data.JoiningStatus);
            setProgressCount(data.data?.ProfileProgress);
            console.log("ProfileProgress count: ", data?.data?.ProfileProgress);
            console.log("joining status: ", data.data.JoiningStatus);

            const vspStatus = await GetVspStatusByID(ID, authToken);
            console.log("vsp status: ", vspStatus);
            setUserVspStatus(vspStatus.vspStatus);
        }
        fetchData();
    }, [authToken])

    useEffect(() => {
        calculateProfileProgress();
        if (userInfo?.JoiningStatus === 'join') {
            console.log("get into join status true");
            setOpenToJoin(true);
        }
    }, [authToken, userInfo])

    const handleOpenToJoin = async (event) => {
        const newValue = event.target.checked;
        setOpenToJoin(newValue);
        console.log("open to join :", newValue);
        const userData = {
            'joiningStatus': newValue ? 'join' : 'null'
        }
        const updateProfile = await UpdateUserProfile(ID, userData, authToken)
        const data = await GetCandidateByID(ID, authToken)
        // if (role === 'Candidate') {
        //     setUserInfo(data.data);
        //     setUserEducation(data.data?.Education);
        //     setUserExperience(data.data?.Experience);
        // }

    };

    useEffect(() => {
        if (authToken) {
            const fetchData = async () => {
                const certData = await fetchCertificationCards(authToken);
                setCertificationCards(certData);
                const jobRoleData = await getAllFakeJobs(authToken);
                console.log(jobRoleData)
                setJobRoles(jobRoleData.data)
            }
            fetchData();
        }
    }, [authToken])

    useEffect(() => {
        if (applyButtonDisable || !scopeDetailModalVisible) {
            const fetchData = async () => {
                const certData = await fetchCertificationCards(authToken);
                setCertificationCards(certData);
                const jobRoleData = await getAllFakeJobs(authToken);
                console.log(jobRoleData)
                setJobRoles(jobRoleData.data)
            }
            fetchData();
        }
    }, [applyButtonDisable, scopeDetailModalVisible])

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchPlacementDrives(authToken);
            console.log('PLACEMENT DRIVES', data.data)
            setPlacementDrives(data.data);
        }
        fetchData();
    }, [authToken])

    const navigate = useNavigate();
    const navigateTo = (URL) => {
        navigate(URL)
    }

    // const calculateProfileProgress = () => {
    //     console.log('Func called', userInfo)
    //     let progressCount = 0
    //     if (userInfo?.FullName && userInfo?.FullName?.trim().length > 3) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.CareerProfile?.degree && userInfo?.CareerProfile?.degree?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Description && userInfo?.Description?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Image && userInfo?.Image?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.SkillProfile?.ProfessionalSkills?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Email && userInfo?.Email?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Phone && userInfo?.Phone?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Dob && userInfo?.Dob?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.Location && userInfo?.Location?.length > 0) {
    //         progressCount = progressCount + 1
    //     }
    //     if (userInfo?.CV && userInfo?.CV?.length > 0) {
    //         progressCount = progressCount + 1
    //     }

    //     const progressPercent = Math.round((progressCount / 10) * 100)
    //     setProfileGraphCount({progressPercentage: progressPercent, progressRemainingPercentage: 100 - parseInt(progressPercent)})
    //     console.log("progress bar count: ", progressPercent);
    // }

    const calculateProfileProgress = () => {
        let progressCount = 0;
        let totalWeightage = 0;

        // 60% weightage items
        const majorWeightage = 60;
        const majorItems = [
            { value: userInfo?.FullName, requiredLength: 3 }, // Check if FullName has at least 3 characters
            { value: userInfo?.CareerProfile?.degree }, // Check if degree exists
            { value: userInfo?.CV }, // Check if CV exists
            { value: userInfo?.Image } // Check if Image exists
        ];

        // Calculate major progress
        let majorProgress = majorItems.reduce((acc, item) => {
            return acc + (item.value && item.value.trim?.().length > (item.requiredLength || 0) ? 1 : 0);
        }, 0);

        // Additional check for ProfessionalSkills array
        if (Array.isArray(userInfo?.SkillProfile?.ProfessionalSkills) && userInfo?.SkillProfile?.ProfessionalSkills?.length > 0) {
            majorProgress += 1;
        }

        // Weight major progress
        progressCount += (majorProgress / (majorItems.length + 1)) * majorWeightage; // +1 for ProfessionalSkills
        totalWeightage += majorWeightage;

        // 20% weightage for Description
        const descriptionWeightage = 20;
        if (userInfo?.Description && userInfo?.Description.trim().length > 0) {
            progressCount += descriptionWeightage;
        }
        totalWeightage += descriptionWeightage;

        // 20% weightage items (Email, Phone, DOB)
        const minorWeightage = 20;
        const minorItems = [
            userInfo?.Email,
            userInfo?.Phone,
            userInfo?.Dob
        ];

        let minorProgress = minorItems.reduce((acc, item) => {
            return acc + (item && item.trim?.().length > 0 ? 1 : 0);
        }, 0);
        progressCount += (minorProgress / minorItems.length) * minorWeightage;
        totalWeightage += minorWeightage;

        // Ensure total progress doesn't exceed 100
        const progressPercent = Math.min(Math.round(progressCount), 100);

        // Set progress values
        setProfileProgressCount(parseInt(progressPercent));
        setProfileProgressCountRem(100 - parseInt(progressPercent));
        setProfileGraphCount({
            progressPercentage: parseInt(progressPercent),
            progressRemainingPercentage: (100 - parseInt(progressPercent))
        });
        console.log("GRAPH BINDING: ", progressPercentage, progressRemainingPercentage);

        console.log("Progress bar count: ", progressPercent);
    };

    const profileStrengthData = {
        datasets: [
            {
                label: '# strong',
                data: [profileProgressCount, profileProgressCountRem],
                backgroundColor: [
                    'rgba(5, 150, 105, 1)',
                    'rgba(229, 229, 229, 1)'
                ],
                borderColor: [
                    'rgba(5, 150, 105, 1)',
                    'rgba(229, 229, 229, 1)'
                ],
                borderWidth: 1,
            },
        ],
        labels: ['Strength']
    };

    const profileStrengthOptions = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom', // Position the legend below the chart
            },
        },
        cutout: '75%',
        maintainAspectRatio: false,
        responsive: true
    };

    const handleViewDriveDetails = (drive) => {
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }

    const calculateCountdown = (fromDate) => {
        const now = moment();
        const fromDateMoment = moment(fromDate);
        const diffDays = fromDateMoment.diff(now, 'days');
        const formattedDate = moment(fromDate).format('DD MMMM YYYY');
        return { diffDays, formattedDate };
    };

    useEffect(() => {
        if (authToken) {
            const fetchAllJobs = async () => {
                const jobsData = await getAllFakeJobs(authToken);
                setAllFakeJobs(jobsData.data)
            }
            getAllFakeJobs()
        }
    }, [authToken])

    // Job Applications of the Candidates
    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive()
            setJobApplicationsCount(data.length)
            const filteredApplication = data.filter(application => application.CandidateID === ID)
            setCandidateJobApplicationsCount(filteredApplication.length);
        }
        fetchPDApplications()
    }, [ID])

    // Course Applications
    useEffect(() => {
        const fetchCourseApplications = async () => {
            const data = await getAllCourseApplications(authToken)
            setCourseApplications(data.data)
        }
        fetchCourseApplications()
    }, [])

    const handleVerification = () => {
        showVerificationModal({ verificationModalVisible: true })
    }

    const handleJobRoleDetail = (jobData, type) => {
        console.log(jobData)
        showScopeDetailModal({ scopeDetailModalVisible: true, scopeDetailData: jobData, scopeType: type })
    }


    const handleApplyJob = (e, jobData, k) => {
        e.stopPropagation();
        setApplyButtonDisable(k)
        let arr = JSON.parse(JSON.stringify(tempDisabledButtonArr))
        arr.push(jobData.ID)
        const data = {
            'EmpID': null,
            'JobID': jobData.ID,
            'JobTitle': jobData.Title,
            'placementID': null,
            'tokenUserID': ID
        }

        const jobs = async () => {
            
            const jobData = await createJobApplication(data, authToken)

            if (jobData?.data === "Job appliation created successfully!") {
                setTempDisabledButtonArr(arr)
                setApplyButtonDisable(null)
                toast.success('Your Skill profile has been shared with the recruiter!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                })
            }
            // console.log(jobData?.data)
        }
        jobs()
    }

    return (
        <>
            <div id='appCapsule'>
                <ToastContainer />
                <div class='container'>
                    <div class="d-flex align-items-center mt-2 mb-2">
                        <h1 className="title mb-0">Dashboard</h1>
                    </div>
                    <div class="row">
                        <div className='col-12 col-md-8'>
                            <div className="row">
                                <div className="col-lg-4 col-6 mb-2">
                                    <div className="card shadow-none border">
                                        <Link to='/all-emp-jobs'>
                                            <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#DBEAFE' }}>
                                                    <User style={{ color: '#9333EA' }} />
                                                </div>
                                                <div className='order-sm-0'>
                                                    <h5 className="card-title mb-0">0</h5>
                                                    <h6 class="mb-0" style={{ fontSize: '13px', height: '11px' }}>Profile Viewers</h6>
                                                    <span class="fs-10 text-secondary">Past 90 days</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6 mb-2">
                                    <div className="card shadow-none border">
                                        <Link to='/emp-placement-drives'>
                                            <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#F3E8FF' }}>
                                                    <Search style={{ color: '#2563EB' }} />
                                                </div>
                                                <div className='order-sm-0'>
                                                    {/* <h5 className="card-title">{empPlacementDriveCount}</h5> */}
                                                    <h5 className="card-title mb-0">0</h5>
                                                    <h6 class="mb-0" style={{ fontSize: '13px', height: '11px' }}>Search Appearances</h6>
                                                    <span class="fs-10 text-secondary">Previous week</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-6 mb-2">
                                    <div className="card shadow-none border">
                                        <Link to='/all-emp-applications'>
                                            <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                <div className='db-bg-icon order-sm-1 border-0' style={{ background: '#FEF3C7' }}>
                                                    <UserCheck style={{ color: '#D97706' }} />
                                                </div>
                                                <div className='order-sm-0'>
                                                    {/* <h5 className="card-title mb-0">{jobAppliationsCount}</h5> */}
                                                    <h5 className="card-title mb-0">0</h5>
                                                    <h6 class="mb-0" style={{ fontSize: '13px', height: '11px' }}>Shortlisted</h6>
                                                    <span class="fs-10 text-secondary">Past 30 days</span>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className='col-12 col-md-12'>
                                    <div class="card shadow-none" style={{ border: 'solid 1px #358D77', background: '#C6E8DF' }}>
                                        <div className='card-body'>
                                            <div class="row">
                                                <div class="d-none d-md-block col-md-1">
                                                    <WandSparkles size={28} style={{ color: '#358D77' }} />
                                                </div>
                                                <div class="col-12 col-md-11">
                                                    <h5 class="mb-0 fw-bold">Looking to enhance your career prospects?</h5>
                                                    <h6 class="mb-0">Sign up for upcoming courses from us & our partners to enhance your skills and broaden your expertise.</h6>
                                                    <ul class="p-0 mt-1 mb-0" style={{ listStyleType: 'none', background: '#f5f5f5', borderRadius: '3px' }}>
                                                        <li class='d-flex align-items-center justify-content-between' style={{ borderRadius: '3px' }}>
                                                            <div class='d-flex align-items-center'>
                                                                <div class="me-1 p-1 pe-0">
                                                                    {!jobRoles[0]?.Image &&
                                                                        <div style={{ backgroundImage: 'url(https://s3-alpha-sig.figma.com/img/5c1f/78ab/371d144d1937e608076ed679e08cb5bd?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=oeQQZPedccRsjcETUBw08wfmLAK3U4vVS7k683akGhfa7ZtJ0FzQtQiIS-hwgNvqicGdVAjldiWF~yktLIdDPqakDlRmGCbpPl3MLPxn2HmuFfC5lWi3p~vM4Xmv1NVbzqVwxA6FQGm6Ft2sfBatX0GP0n-7Z9DLtgnAGxPM97zzq7VEHSDJzqC1VhwVLQtONoBICkGrMb1SE0ho~ffrN7ItvIuZGfbeaK-ivPFS~QUkIUgCnXBoYS3vWys7830rK4XrEEEn0bUX2Q53ky1jClhs347ok7WiOmBCbGbi67qL-0qzV3hU01vBln3Oo5uxD4BWnxKriP4m650bvdTlnQ__)', height: '30px', width: '30px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '3px' }}>

                                                                        </div>
                                                                    }
                                                                    {jobRoles[0]?.Image &&
                                                                        <div style={{ backgroundImage: 'url(' + jobRoles[0]?.Image + ')', height: '30px', width: '30px', backgroundSize: 'cover', backgroundPosition: 'center', borderRadius: '3px' }}>

                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <h5 class="mb-0">{jobRoles[0]?.JobRole}</h5>
                                                                    <h6 class="mb-0 text-secondary">{jobRoles[0]?.Experience} - {jobRoles[0]?.Location}</h6>
                                                                </div>
                                                            </div>
                                                            <div class="me-2">
                                                                <h6 class="mb-0 text-primary text-nowrap cursor-pointer" onClick={() => { handleJobRoleDetail(jobRoles[0], 'jobs') }}>View Details <ArrowUpRight size={12} /></h6>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2 mb-sm-0">
                                <div class="col-12 col-md-6 mb-2 mb-sm-0">
                                    <div class="text-center d-block d-md-none mb-2">
                                        <button class="btn btn-block text-white" onClick={() => { handleVerification() }} style={{ background: '#2563EB' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#ffffff" stroke="#2563EB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                                            <span class="ms-1">Get Verified</span>
                                        </button>
                                        <span class="text-muted">Verified candidates have 60% more visibility</span>
                                    </div>
                                    <div class="mt-2 d-block d-md-none">
                                        <div class="card shadow-none border-0 mb-2 cursor-pointer">
                                            <div class="card-body d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 class="mb-1">Profile Strength</h4>
                                                    <a href='javaScript:void(0)' class="mb-0 cursor-pointer" onClick={() => navigateTo('/candidate-profile')}><h5 class="text-primary"><u>View Profile</u></h5></a>
                                                </div>
                                                <div class="h-auto d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                                    <div class="d-flex align-items-center justify-content-center flex-column text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                        <h6 class="mb-0 fw-bold">{progressPercentage}%</h6>
                                                    </div>
                                                    <Doughnut data={profileStrengthData} options={profileStrengthOptions} width={50} height={50} />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card shadow-none mb-2 d-none">
                                            <div class="card-body">
                                                <div class="d-flex mb-2">
                                                    <div class="w-100 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h4 class="mb-0 d-flex align-items-center">
                                                                <span>Open for Job</span>
                                                            </h4>
                                                        </div>
                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input form-switch-toggle" type="checkbox" id="SwitchCheckDefault4" />
                                                                <label class="form-check-label" for="SwitchCheckDefault4"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mb-2">
                                                    <h6 style={{ marginBottom: '3px' }}>Joining</h6>
                                                    <Form.Select aria-label="Default select example" className="border" style={{ fontSize: '14px' }}>
                                                        <option>Professional Skills</option>
                                                        <option value="1">One</option>
                                                        <option value="2">Two</option>
                                                        <option value="3">Three</option>
                                                    </Form.Select>
                                                </div>
                                                <div class="d-flex flex-wrap mb-0">
                                                    <div class="form-check m-0 me-2 mb-1">
                                                        <input class="form-check-input" type="checkbox" value="" id="fullTime" />
                                                        <label class="form-checkbox-custom form-check-label ps-3" for="fullTime">
                                                            <h6 class="mb-0 text-nowrap">Full Time</h6>
                                                        </label>
                                                    </div>
                                                    <div class="form-check m-0 me-2 mb-1">
                                                        <input class="form-check-input" type="checkbox" value="" id="partTime" />
                                                        <label class="form-checkbox-custom form-check-label ps-3" for="partTime">
                                                            <h6 class="mb-0 text-nowrap">Part Time</h6>
                                                        </label>
                                                    </div>
                                                    <div class="form-check m-0 me-2 mb-1">
                                                        <input class="form-check-input" type="checkbox" value="" id="internship" />
                                                        <label class="form-checkbox-custom form-check-label ps-3" for="internship">
                                                            <h6 class="mb-0 text-nowrap">Internship</h6>
                                                        </label>
                                                    </div>
                                                    <div class="form-check m-0 me-2 mb-1">
                                                        <input class="form-check-input" type="checkbox" value="" id="remote" />
                                                        <label class="form-checkbox-custom form-check-label ps-3" for="remote">
                                                            <h6 class="mb-0 text-nowrap">Remote</h6>
                                                        </label>
                                                    </div>
                                                    <div class="form-check m-0 me-2 mb-1">
                                                        <input class="form-check-input" type="checkbox" value="" id="onField" />
                                                        <label class="form-checkbox-custom form-check-label ps-3" for="onField">
                                                            <h6 class="mb-0 text-nowrap">On Field</h6>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card shadow-none mb-2">
                                            <div class="card-body">
                                                <div class="d-flex mb-0">
                                                    <div class="w-100 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h4 class="mb-0 fw-bold d-flex align-items-center">
                                                                <span>Open for Join</span>
                                                            </h4>
                                                        </div>
                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input form-switch-toggle" type="checkbox" id="openForJob" checked={openToJoin} onChange={handleOpenToJoin} />
                                                                <label class="form-check-label" for="openForJob"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card shadow-none mb-2 d-none">
                                            <div class="card-body">
                                                <div class="d-flex">
                                                    <div class="w-100 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h4 class="mb-0">
                                                                Open for Freelancing
                                                            </h4>
                                                        </div>
                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input form-switch-toggle" type="checkbox" id="SwitchCheckDefault4" />
                                                                <label class="form-check-label" for="SwitchCheckDefault4"></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card shadow-none mb-2" style={{ background: '#fafafa' }}>
                                            <div class="card-body">
                                                <div class="d-flex mb-0">
                                                    <div class="w-100 d-flex align-items-center justify-content-between">
                                                        <div>
                                                            <h4 class="mb-0 fw-bold d-flex align-items-center">
                                                                <span>Open for Freelancing</span>
                                                            </h4>
                                                        </div>
                                                        <div class="d-flex justify-content-center align-items-center flex-column">
                                                            <div class="form-check form-switch">
                                                                <input class="form-check-input form-switch-toggle" type="checkbox" id="freelancing" disabled />
                                                                <label class="form-check-label" for="freelancing"></label>
                                                            </div>
                                                            <h6 class="mb-0 text-danger">Coming Soon</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card shadow-none h-100 mb-2" style={{ maxHeight: '320px', overflow: 'scroll' }}>
                                        <div class="card-body pb-0">
                                            <h4>Scope for you</h4>
                                            <ul class="p-0 m-0" style={{ listStyleType: 'none' }}>
                                                {(jobRoles.length == 0 || !jobRoles) &&
                                                    <>
                                                        {[1,2,3,4].map((v,k) => {
                                                            return (
                                                                <div class="border mb-1 p-1 d-flex justify-content-between" style={{ borderRadius: '5px' }}>
                                                                    <div class="placeholder-glow w-100">
                                                                        <span class="placeholder col-12"></span>
                                                                        <span class="placeholder col-8"></span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </>
                                                }
                                                {(jobRoles && jobRoles.length > 0) &&
                                                    <>
                                                        {jobRoles.slice(0, 5).map((v, k) => {
                                                            return (
                                                                <>
                                                                    {k != 0 &&
                                                                        <li class="mb-1 cursor-pointer" onClick={() => { handleJobRoleDetail(v, 'jobs') }} key={k}>
                                                                            <div class="border p-1 d-flex justify-content-between" style={{ borderRadius: '5px' }}>
                                                                                <div>
                                                                                    <h5 class="fw-bold" style={{ marginBottom: '3px' }}>{v.JobRole}</h5>
                                                                                    <h6 class="mb-0 text-secondary">{v.Experience} - {v.Location}</h6>
                                                                                </div>
                                                                                <div class="d-flex align-items-center" onClick={(e) => {handleApplyJob(e, v, k)}}>
                                                                                    <ChevronRight size={18} />
                                                                                    {v.Status != 'Applied' &&
                                                                                        <button class="btn btn-sm text-primary" disabled={tempDisabledButtonArr.includes(v.ID)} style={{background: '#f5f5f5'}}>{(tempDisabledButtonArr.includes(v.ID)) ? "Applied" : "Apply"}</button>
                                                                                    }
                                                                                    {v.Status == 'Applied' &&
                                                                                        <button class="btn btn-sm text-primary" disabled={true} style={{background: '#f5f5f5'}}>Applied</button>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    }
                                                                </>
                                                            )
                                                        })}
                                                    </>
                                                }
                                                <li class="d-flex justify-content-center"><span class="text-primary text-center cursor-pointer" onClick={() => navigateTo('/jobs')} style={{fontSize: '14px'}}>View all jobs</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="card shadow-none h-100" style={{ maxHeight: '320px', overflow: 'scroll' }}>
                                        <div class="card-body pb-0">
                                            <h4>Placement Drives</h4>
                                            <ul class="p-0 m-0" style={{ listStyleType: 'none' }}>
                                                {placementDrives?.slice(0, 5).map((v, k) => {
                                                    return (
                                                        <>
                                                            <li class="mb-1 cursor-pointer" onClick={() => { handleJobRoleDetail(v, 'drives') }}>
                                                                <div class="border p-1 d-flex justify-content-between" style={{ borderRadius: '5px' }}>
                                                                    <div>
                                                                        <h5 class="fw-bold" style={{ marginBottom: '3px' }}>{v.Title}</h5>
                                                                        <h6 class="mb-0 text-secondary">{v.Qualification} - {v.Location}</h6>
                                                                    </div>
                                                                    <div class="d-flex align-items-center">
                                                                        <ChevronRight size={18} />
                                                                        {/* <button class="btn btn-sm text-primary" style={{background: '#f5f5f5'}}>Apply</button> */}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div class="card shadow-none h-100">
                                        <div class="card-body">
                                            <h4>
                                                <span class="me-1">Contacted for Interview</span>
                                                <span class="badge bg-primary text-white p-0 ps-1 pe-1" style={{minWidth: 'fit-content', height: '16px'}}>3</span>
                                            </h4>
                                            <h2 class="text-secondary d-flex justify-content-center">No Interviews Yet</h2>
                                            <ul class="p-0 m-0" style={{listStyleType: 'none'}}>
                                                <li class="mb-1">
                                                    <div class="border p-1 d-flex justify-content-between" style={{borderRadius: '5px'}}>
                                                        <div>
                                                            <h5 class="mb-0 fw-bold">Arjun Patel</h5>
                                                            <h6 class="mb-0 text-secondary">TechWave Innovations Inc.</h6>
                                                        </div>
                                                        <div>
                                                            <button class="btn btn-sm p-1" style={{background: '#f5f5f5'}}>
                                                                <Mail size={18} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="mb-1">
                                                    <div class="border p-1 d-flex justify-content-between" style={{borderRadius: '5px'}}>
                                                        <div>
                                                            <h5 class="mb-0 fw-bold">Priya Sharma</h5>
                                                            <h6 class="mb-0 text-secondary">Quantum Solutions LLC.</h6>
                                                        </div>
                                                        <div>
                                                            <button class="btn btn-sm p-1" style={{background: '#f5f5f5'}}>
                                                                <Mail size={18} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li class="mb-1">
                                                    <div class="border p-1 d-flex justify-content-between" style={{borderRadius: '5px'}}>
                                                        <div>
                                                            <h5 class="mb-0 fw-bold">Neha Verma</h5>
                                                            <h6 class="mb-0 text-secondary">NextGen Systems Corp.</h6>
                                                        </div>
                                                        <div>
                                                            <button class="btn btn-sm p-1" style={{background: '#f5f5f5'}}>
                                                                <Mail size={18} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div class="text-center d-none d-md-block">
                                <button class="btn btn-block text-white" onClick={() => { handleVerification() }} style={{ background: '#2563EB' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="#ffffff" stroke="#2563EB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-badge-check"><path d="M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z" /><path d="m9 12 2 2 4-4" /></svg>
                                    <span class="ms-1">Get Verified</span>
                                </button>
                                <span class="text-muted">Verified candidates have 60% more visibility</span>
                            </div>
                            <div class="mt-2 d-none d-md-block">
                                <div class="card shadow-none border-0 mb-2 cursor-pointer">
                                    <div class="card-body d-flex align-items-center justify-content-between">
                                        <div>
                                            <h4 class="mb-1">Profile Strength</h4>
                                            <a href='javaScript:void(0)' class="mb-0 cursor-pointer" onClick={() => navigateTo('/candidate-profile')}><h5 class="text-primary"><u>View Profile</u></h5></a>
                                        </div>
                                        <div class="h-auto d-flex align-items-center justify-content-center" style={{ position: 'relative' }}>
                                            <div class="d-flex align-items-center justify-content-center flex-column text-center" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <h6 class="mb-0 fw-bold">{progressPercentage}%</h6>
                                            </div>
                                            <Doughnut data={profileStrengthData} options={profileStrengthOptions} width={50} height={50} />
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-2 d-none">
                                    <div class="card-body">
                                        <div class="d-flex mb-2">
                                            <div class="w-100 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 class="mb-0 d-flex align-items-center">
                                                        <span>Open for Job</span>
                                                    </h4>
                                                </div>
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input form-switch-toggle" type="checkbox" id="SwitchCheckDefault4" />
                                                        <label class="form-check-label" for="SwitchCheckDefault4"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-2">
                                            <h6 style={{ marginBottom: '3px' }}>Joining</h6>
                                            <Form.Select aria-label="Default select example" className="border" style={{ fontSize: '14px' }}>
                                                <option>Professional Skills</option>
                                                <option value="1">One</option>
                                                <option value="2">Two</option>
                                                <option value="3">Three</option>
                                            </Form.Select>
                                        </div>
                                        <div class="d-flex flex-wrap mb-0">
                                            <div class="form-check m-0 me-2 mb-1">
                                                <input class="form-check-input" type="checkbox" value="" id="fullTime" />
                                                <label class="form-checkbox-custom form-check-label ps-3" for="fullTime">
                                                    <h6 class="mb-0 text-nowrap">Full Time</h6>
                                                </label>
                                            </div>
                                            <div class="form-check m-0 me-2 mb-1">
                                                <input class="form-check-input" type="checkbox" value="" id="partTime" />
                                                <label class="form-checkbox-custom form-check-label ps-3" for="partTime">
                                                    <h6 class="mb-0 text-nowrap">Part Time</h6>
                                                </label>
                                            </div>
                                            <div class="form-check m-0 me-2 mb-1">
                                                <input class="form-check-input" type="checkbox" value="" id="internship" />
                                                <label class="form-checkbox-custom form-check-label ps-3" for="internship">
                                                    <h6 class="mb-0 text-nowrap">Internship</h6>
                                                </label>
                                            </div>
                                            <div class="form-check m-0 me-2 mb-1">
                                                <input class="form-check-input" type="checkbox" value="" id="remote" />
                                                <label class="form-checkbox-custom form-check-label ps-3" for="remote">
                                                    <h6 class="mb-0 text-nowrap">Remote</h6>
                                                </label>
                                            </div>
                                            <div class="form-check m-0 me-2 mb-1">
                                                <input class="form-check-input" type="checkbox" value="" id="onField" />
                                                <label class="form-checkbox-custom form-check-label ps-3" for="onField">
                                                    <h6 class="mb-0 text-nowrap">On Field</h6>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-2">
                                    <div class="card-body">
                                        <div class="d-flex mb-0">
                                            <div class="w-100 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 class="mb-0 fw-bold d-flex align-items-center">
                                                        <span>Open for Job</span>
                                                    </h4>
                                                </div>
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input form-switch-toggle" type="checkbox" id="openForJob" checked={openToJoin} onChange={handleOpenToJoin} />
                                                        <label class="form-check-label" for="openForJob"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-2 d-none">
                                    <div class="card-body">
                                        <div class="d-flex">
                                            <div class="w-100 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 class="mb-0">
                                                        Open for Freelancing
                                                    </h4>
                                                </div>
                                                <div class="d-flex justify-content-end align-items-center">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input form-switch-toggle" type="checkbox" id="SwitchCheckDefault4" />
                                                        <label class="form-check-label" for="SwitchCheckDefault4"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card shadow-none mb-2" style={{ background: '#fafafa' }}>
                                    <div class="card-body">
                                        <div class="d-flex mb-0">
                                            <div class="w-100 d-flex align-items-center justify-content-between">
                                                <div>
                                                    <h4 class="mb-0 fw-bold d-flex align-items-center">
                                                        <span>Open for Freelancing</span>
                                                    </h4>
                                                </div>
                                                <div class="d-flex justify-content-center align-items-center flex-column">
                                                    <div class="form-check form-switch">
                                                        <input class="form-check-input form-switch-toggle" type="checkbox" id="freelancing" disabled />
                                                        <label class="form-check-label" for="freelancing"></label>
                                                    </div>
                                                    <h6 class="mb-0 text-danger">Coming Soon</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="appCapsule" className='Dashboard d-none'>
                {/* Dashboard Count View Start */}
                <div className='section full py-4'>
                    <div className='container'>
                        {/* <h1 className="title text-light">Dashboard</h1> */}
                        <div className="row">
                            <div className="col-lg-3 col-6 mb-2">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="person-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">0</h5>
                                            <h6 className="card-subtitle">Total Visitors</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6 mb-2">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">0</h5>
                                            <h6 className="card-subtitle">Shortlisted</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-6 mb-2">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="eye-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">NaN</h5>
                                            <h6 className="card-subtitle">Views</h6>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-3 col-6 mb-2">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="checkmark-done-outline"></ion-icon>
                                        </div>
                                        <div className='order-sm-0'>
                                            <h5 className="card-title">{role === 'Candidate' ? candidiateJobApplicationsCount : jobApplicationsCount}</h5>
                                            <h6 className="card-subtitle">Applied Jobs</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /////////////////////////////////////////////////// joining status */}
                            <div className="col-lg-3 col-6 mb-2">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                        <div className='db-bg-icon order-sm-1'>
                                            <ion-icon size="large" name="toggle-outline" ></ion-icon>
                                        </div>
                                        {
                                            openToStatus === 'join' ?
                                                <>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">Join</h5>
                                                        <h6 className="card-subtitle">New Opportunity</h6>
                                                    </div>
                                                </> : <></>
                                        }

                                        {
                                            openToStatus === 'switch' ?
                                                <>
                                                    <div className='order-sm-0'>
                                                        <h5 className="card-title">Switch</h5>
                                                        <h6 className="card-subtitle">New Opportunity</h6>
                                                    </div>
                                                </> : <></>
                                        }

                                        {
                                            openToStatus === 'null' || openToStatus == null || openToStatus == '' ?
                                                <>
                                                    <div className='ready-box order-sm-0'>
                                                        <h5 className="card-title">Ready</h5>
                                                        <h6 className="card-subtitle">Ready For New Opportunity?</h6>
                                                    </div>
                                                </> : <></>
                                        }
                                    </div>
                                </div>
                            </div>



                            {role === 'Admin' &&
                                <div className="col-lg-3 col-6 mb-2">
                                    <Link to='/course-applications'>
                                        <div className="card">
                                            <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                                <div className='db-bg-icon order-sm-1'>
                                                    <ion-icon size="large" name="people-outline"></ion-icon>
                                                </div>
                                                <div className='order-sm-0'>
                                                    <h5 className="card-title">{courseApplications && courseApplications.length}</h5>
                                                    <h6 className="card-subtitle">Course Applications</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            }

                        </div>
                    </div>
                </div>
                {/* Dashboard Count View End */}
                {/* User Profile Start */}
                <div className="section full mt-2 mb-2 pt-4 Dashboard">
                    <div className='container'>
                        <Row>
                            <Col md={9} sm={12}>
                                <div>
                                    <h1 className="title">Hi, {fullName}</h1>
                                    <h2 className='sub-title'>Welcome to your Dashboard !</h2>
                                    {/* <p>Here is how your profile is performing on Scopehai Talent platform.</p> */}
                                </div>
                            </Col>
                            <Col md={3} sm={12} className='d-flex justify-content-center align-items-center'>
                                {role === "Candidate" && (
                                    < button
                                        type="button"
                                        class="btn btn-primary"
                                        onClick={() => navigateTo('/candidate-profile')}>
                                        {progressCount === 100 ? 'Edit Profile' : 'Complete Profile'}<i class="fa fa-arrow-right ms-1"></i>
                                    </button>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={9} sm={12}>
                                <h2>Get a verified skill profile (VSP) to get higher chances of success</h2>
                                <h3>Verified skill profiles receive 70% more opportunities on Scopehai</h3>
                            </Col>
                            <Col md={3} sm={12} className='d-flex justify-content-center align-items-center'>
                                {role === "Candidate" &&
                                    <button
                                        type="button"
                                        disabled={userVspStatus ? true : false}
                                        class="btn btn-outline-primary vsp-btn"
                                        onClick={() => setShowGetVspModal(true)}>
                                        {userVspStatus === true ? 'VSP Verified' : userVspStatus === false ? 'VSP Applied' : 'Get VSP'}
                                        {
                                            userVspStatus === true ? <img className='imaged w-20 ms-1 rounded' src='/static/media/Favicon_large-removebg-preview.8238d064.png'></img> : <i class="fa fa-arrow-right ms-1"></i>
                                        }
                                    </button>
                                }
                            </Col>
                        </Row>
                        <GetVspModal
                            // onHide={() => setVspModalShow(false)}
                            vspStatus={userVspStatus}
                            show={showGetVspModal}
                            handleClose={handleCloseGetVspModal}
                        />
                    </div>
                </div>
                {/* User Profile End */}
                {/* Certificates Slider Start */}
                <div className="section full mt-4 mb-5">
                    <div className='container'>
                        <h2>Recommended training and placement programs</h2>
                        <h3>Here are the recommended training and placement programs for you.</h3>
                        <p>We have considered the data you have submitted with us and our smart algorithms have come up with some upskilling options for you.</p>
                        <Splide
                            options={{
                                rewind: false,
                                gap: '1rem',
                                perMove: 1,
                                perPage: 3,
                                type: 'loop',
                                autoplay: true,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {certificationCards.map((v, k) => {
                                return (
                                    <SplideSlide>
                                        <CertificationCardComp v={v}></CertificationCardComp>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    </div>
                </div>
                {/* Certificates Slider End */}
                {/* Placement Drives Start */}
                <div className="section full mt-2 mb-4 py-4">
                    <div className='container'>
                        <h2>Current Placement Drives</h2>
                        {/* <Row xs={1} md={2} className="g-4"> */}
                        <Splide
                            options={{
                                rewind: false,
                                gap: '.5rem',
                                perMove: 1,
                                perPage: 2,
                                type: 'loop',
                                autoplay: true,
                                interval: 3000,
                                breakpoints: {
                                    875: {
                                        perPage: 2
                                    },
                                    585: {
                                        perPage: 1
                                    }
                                }
                            }}
                            aria-label="React Splide Example">
                            {placementDrives.map((drive, idx) => (
                                <SplideSlide>
                                    <>
                                        <PlacementDriveCard key={idx} drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                    </>
                                </SplideSlide>
                            ))}
                        </Splide>
                        {/* </Row> */}
                    </div>
                </div>

                {/* <EmpPlacementDrives></EmpPlacementDrives> */}
                {/* Placement Drives End */}
                {/* Jobs List Start */}
                <div className="section full mt-4 mb-4">
                    <div className='container'>
                        {/* <div className="section-title ps-0">Top jobs matching your profile</div> */}
                        <h2>Top jobs matching your profile</h2>
                        <p>We have considered your profile and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p>
                        {/* <ul className="listview image-listview media">
                        { allJobs.map((job, idx) => (
                                    <li className='my-2' key={idx}>
                                        <div className="item">
                                            <div className="imageWrapper in">
                                                <div className="in">
                                                    <div>
                                                        <div className="text-muted">{job?.Type}</div>
                                                        {job?.Title}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="in">
                                                <div>
                                                    <div className="text-muted">{job?.Location}</div>
                                                    {job?.CtcFrom} - {job?.CtcTo} LPA
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center'>
                                                <Button variant="primary">Apply</Button>
                                            </div>
                                        </div>
                                    </li>)
                                )}
                        </ul> */}
                        <JobListCard allFakeJobs={allFakeJobs} />
                    </div>
                </div>
                {/* Jobs List End */}
            </div >
        </>
    )
}

const mapStateToProps = ({ auth, data, scopeDetail }) => {
    const { userData, role, fullName, ID, authToken } = auth
    const { progressPercentage, progressRemainingPercentage } = data
    const { scopeDetailModalVisible } = scopeDetail

    return {
        userData,
        role,
        fullName,
        ID,
        authToken,
        progressPercentage,
        progressRemainingPercentage,
        scopeDetailModalVisible
    }
}

const mapDispatchToProps = {
    showVerificationModal,
    showScopeDetailModal,
    setProfileGraphCount
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)