import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    scopeDetailModalVisible: false,
    scopeDetailData: null,
    scopeType: null
}

export const ScopeDetailModalSlice = createSlice({
  name: 'scopeDetail',
  initialState,
  reducers: {
    showScopeDetailModal: (state, action) => {
      const {
        scopeDetailModalVisible,
        scopeDetailData,
        scopeType
      } = action.payload
      state.scopeDetailModalVisible=scopeDetailModalVisible
      state.scopeDetailData=scopeDetailData
      state.scopeType=scopeType
    },
    hideScopeDetailModal: (state, action) => {
        state.scopeDetailModalVisible=false
        state.scopeDetailData=null
        state.scopeType=null
    }
  }
})

export const { showScopeDetailModal, hideScopeDetailModal } = ScopeDetailModalSlice.actions;


export default ScopeDetailModalSlice.reducer
