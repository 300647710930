import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, getRemovedSkills, handelOpenToWork, postUserImage, shortlistApplicant, UserJoinStatus, postUserEducation, updateEducationRecord } from '../../../../features/constants/apis';
import { hideProfileUpdateModal } from '../../../../features/popup/ProfileUpdateModalSlice';
import { array } from 'prop-types';
import { showAlert } from '../../../../features/utilities/appToastSlice';


const AddEducation = ({ userInfo, education, hideProfileUpdateModal, showAlert }) => {
    console.log(' education education education:', education);
    const [isEditing, setIsEditing] = useState(false);
    const [expID, setExpID] = useState(1)
    const [school, setSchool] = useState('');
    const [degree, setDegree] = useState('');
    const [studyField, setStudyField] = useState('');
    const [grade, setGrade] = useState('');
    const [validated, setValidated] = useState(false);
    const [popUpLoader, setPopUpLoader] = useState(false);
    const [startMonth, setStartMonth] = useState('')
    const [selectStartYear, setSelecteStartYear] = useState("");
    const [endMonth, setEndMonth] = useState('');
    const [selectEndYar, setSelectEndYar] = useState('');

    const startYear = 2000; // Starting year
    const endYear = new Date().getFullYear(); // Current year
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

    useEffect(() => {
        if (education == undefined) {

        }
        else {
            console.log('useEffect called');
            setIsEditing(true)
            setExpID(education?.eduID)
            setSchool(education?.school)
            setDegree(education?.degree)
            setStudyField(education?.studField)
            setGrade(education?.grade)
            setStartMonth(education?.startMont)
            setSelecteStartYear(education?.startYear)
            setEndMonth(education?.endMonth)
            setSelectEndYar(education?.endYear)
        }
    }, [education])

    console.log('school:', school);
    console.log('degree', degree);
    console.log('study field', studyField);
    console.log('startMonth: ', startMonth);
    console.log('startYear:', selectStartYear);
    console.log('endMonth', endMonth);
    console.log('endYear:', selectEndYar);
    console.log('Grade:', grade);
    const authToken = localStorage.getItem("token");
    console.log('authtoken from local storage', authToken);

    const editEducation = async (e) => {
        console.log('fuc triggerd education update');
        setPopUpLoader(true);
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // console.log('form is submitting...')

            const educationData = {
                'eduID': expID,
                'school': school,
                'degree': degree,
                'studField': studyField,
                'startMont': startMonth,
                'startYear': selectStartYear,
                'endMonth': selectEndYar,
                'endYear': selectEndYar,
                'grade': grade

            };

            // console.log(experienceData)
            if (isEditing) {
                // Edit existing record
                console.log('Updating record...', educationData);
                await updateEducationRecord(authToken, userInfo?.ID, educationData,);

                showAlert({ alertMessage: 'success', alertColor: 'primary', alertVisible: true });
                setTimeout(() => { showAlert({ alertMessage: null, alertColor: null, alertVisible: false }); }, 3000)
            } else {
                // Add new record
                console.log('Adding new record...', educationData);
                await postUserEducation(userInfo?.ID, educationData, authToken);

                showAlert({ alertMessage: 'success', alertColor: 'primary', alertVisible: true });
                setTimeout(() => { showAlert({ alertMessage: null, alertColor: null, alertVisible: false }); }, 3000)
            }
            hideProfileUpdateModal()
            // await postUserEducation(userInfo?.ID, experienceData, authToken)
            setPopUpLoader(false);
        }
    }





    return (
        <div>
            <div class="row">
                <form onSubmit={editEducation}>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="school" style={{ fontSize: '14px', marginBottom: '3px' }}>School</label>
                                <input type="text" class="form-control form-control-sm border" id="school" placeholder="Ex. Pune University" autocomplete="off"
                                    value={school}
                                    onChange={(e) => setSchool(e.target.value)} />

                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="degree" style={{ fontSize: '14px', marginBottom: '3px' }}>Degree</label>
                                <input type="text" class="form-control form-control-sm border" id="degree" placeholder="Ex. Bachelor's" autocomplete="off"
                                    value={degree}
                                    onChange={(e) => setDegree(e.target.value)}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="filedStudy" style={{ fontSize: '14px', marginBottom: '3px' }}>Filed of Study</label>
                                <input type="text" class="form-control form-control-sm border" id="filedStudy" placeholder="Ex. Business" autocomplete="off"
                                    value={studyField}
                                    onChange={(e) => setStudyField(e.target.value)}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="startDate" style={{ fontSize: '14px', marginBottom: '3px' }}>Start Date</label>
                                <div class="d-flex">
                                    <select class="form-control form-select-sm form-select border me-2" id="month"
                                        value={startMonth}
                                        onChange={(e) => setStartMonth(e.target.value)}>
                                        <option value={null} selected>Month</option>
                                        <option value="jan">January</option>
                                        <option value="feb">February</option>
                                        <option value="mar">March</option>
                                        <option value="apr">April</option>
                                        <option value="may">May</option>
                                        <option value="jun">June</option>
                                        <option value="jul">July</option>
                                        <option value="aug">August</option>
                                        <option value="sep">September</option>
                                        <option value="oct">October</option>
                                        <option value="nov">November</option>
                                        <option value="dec">December</option>
                                    </select>
                                    <select class="form-control form-select-sm form-select border" id="year"
                                        value={selectStartYear}
                                        onChange={(e) => setSelecteStartYear(e.target.value)}>
                                        <option value="0">Year</option>
                                        {years.map((year) => (
                                            <option value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="startDate" style={{ fontSize: '14px', marginBottom: '3px' }}>End Date (or expected)</label>
                                <div class="d-flex">
                                    <select class="form-control form-select-sm form-select border me-2" id="month"
                                        value={endMonth}
                                        onChange={(e) => setEndMonth(e.target.value)}>
                                        <option value={null} selected>Month</option>
                                        <option value="jan">January</option>
                                        <option value="feb">February</option>
                                        <option value="mar">March</option>
                                        <option value="apr">April</option>
                                        <option value="may">May</option>
                                        <option value="jun">June</option>
                                        <option value="jul">July</option>
                                        <option value="aug">August</option>
                                        <option value="sep">September</option>
                                        <option value="oct">October</option>
                                        <option value="nov">November</option>
                                        <option value="dec">December</option>
                                    </select>
                                    <select class="form-control form-select-sm form-select border" id="year"
                                        value={selectEndYar}
                                        onChange={(e) => setSelectEndYar(e.target.value)}
                                    >
                                        <option value="0">Year</option>
                                        {years.map((year) => (
                                            <option value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="grade" style={{ fontSize: '14px', marginBottom: '3px' }}>Grade</label>
                                <input type="text" class="form-control form-control-sm border" id="grade" placeholder="" autocomplete="off"
                                    value={grade}
                                    onChange={(e) => setGrade(e.target.value)}
                                />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 text-end mt-1">
                        <button disabled={popUpLoader} type='submit' class="btn btn-primary">{popUpLoader ? 'Saving...' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, submitFormStep1, submitFormStep2, candidateAuth
    } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        submitFormStep1,
        submitFormStep2,
        candidateAuth
    }
}

const mapDispatchToProps = {
    hideProfileUpdateModal,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEducation)