import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, getRemovedSkills, handelOpenToWork, postUserImage, shortlistApplicant, UserJoinStatus } from '../../../../features/constants/apis';
import { hideProfileUpdateModal } from '../../../../features/popup/ProfileUpdateModalSlice';


const EditPersonalInfo = ({ userInfo, authToken, hideProfileUpdateModal }) => {
    console.log('inside edit form: ', userInfo);
    const [warning, setWarning] = useState('');
    const [popupLoder, setPopUpLoder] = useState(false);

    // Split FullName into fName and lName
    const [userData, setUserData] = useState({
        email: userInfo?.Email,
        phone: userInfo?.Phone,
        location: userInfo?.Location || '',
        description: userInfo?.Description || '',
        dob: userInfo?.Dob || '',
        website: userInfo?.Website || '',
    });

    // Handle input changes
    const handleChange = (e) => {
        const { id, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [id]: value, // Update field based on input ID
        }));
    };



    // Effect to handle phone validation
    // useEffect(() => {
    //     if (userData?.phone) {
    //         // Remove non-numeric characters to ensure it's just a number
    //         const numericPhone = userData.phone.replace(/\D/g, '');

    //         if (numericPhone.length !== 10) {
    //             setWarning('Phone number must be exactly 10 digits.');
    //         } else {
    //             setWarning('');
    //         }
    //     }
    // }, [userData?.phone]); // Run the effect whenever the phone number changes



    const EditUserProfile = async (e) => {
        setPopUpLoder(true);
        e.preventDefault()
        console.log('editprofile fuc triggerd!!!');
        // const combinedName = `${userData.fName} ${userData.lName}`.trim();
        const updateProfile = await UpdateUserProfile(userInfo?.ID, userData, authToken)
        console.log("user Data: ", updateProfile);
        hideProfileUpdateModal();
        // const data = await GetCandidateByID(ID, authToken)
        // if (role === 'Candidate') {
        //     setUserInfo(data.data);
        //     setUserEducation(data.data?.Education);
        //     setUserExperience(data.data?.Experience);
        // }
        setPopUpLoder(false);
    }

    console.log('userData: ', userData);

    return (
        <div>
            <div class="row">
                <form onSubmit={EditUserProfile}>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="emailAddress" style={{ fontSize: '14px', marginBottom: '3px' }}>Email Address</label>
                                <div class="d-flex">
                                    <input type="email" class="form-control form-control-sm border" placeholder="aditi.sharma@gmail.com" id='email' value={userData?.email} onChange={handleChange} autocomplete="off" />
                                    <button class="btn btn-link text-primary">Edit</button>
                                </div>
                            </div>
                        </div>
                        {/* <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="phone" style={{ fontSize: '14px', marginBottom: '3px' }}>Phone</label>
                                <div class="d-flex">
                                    <input type="tel" class="form-control form-control-sm border" onChange={handleChange} value={userData?.phone} id="phone" placeholder="+91 9329 234 2343" autocomplete="off" />
                                    <button class="btn btn-link text-primary">Edit</button>
                                </div>
                                {warning && <small style={{ color: 'red' }}>{warning}</small>}

                            </div>
                        </div> */}
                        <div class="col-12 col-md-12 p-0">
                            <div class="form-group boxed">
                                <div class="input-wrapper">
                                    <label class="form-label fw-bold" for="currentLocation" style={{ fontSize: '14px', marginBottom: '3px' }}>Current Location</label>
                                    <div class="d-flex">
                                        <select value={setUserData?.location} id='location' onChange={handleChange} class="form-control form-select-sm form-select border me-2">
                                            <option value="Mumbai">Mumbai</option>
                                            <option value="Pune">Pune</option>
                                            <option value="Nashik">Nashik</option>
                                            <option value="Satara">Satara</option>
                                        </select>
                                        {/* <select class="form-control form-select-sm form-select border" id="city">
                                            <option value="Mumbai">Mumbai</option>
                                            <option value="Pune">Pune</option>
                                            <option value="Nashik">Nashik</option>
                                            <option value="Satara">Satara</option>
                                        </select> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="headline" style={{ fontSize: '14px', marginBottom: '3px' }}>Headline</label>
                                <textarea id="description" value={userData?.description} onChange={handleChange} rows="5" class="form-control form-control-sm border" placeholder='Ex. Microsoft'></textarea>
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="dob" style={{ fontSize: '14px', marginBottom: '3px' }}>Date of Birth</label>
                                <input type="date" class="form-control form-control-sm border pe-1" onChange={handleChange} value={userData?.dob} id="dob" autocomplete="off" />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="link" style={{ fontSize: '14px', marginBottom: '3px' }}>Profile / Portfolio</label>
                                <input type="text" value={userData?.website} onChange={handleChange} class="form-control form-control-sm border pe-1" id="website" autocomplete="off" />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 text-end mt-1">
                        <button disabled={popupLoder} type='submit' class="btn btn-primary">{popupLoder ? 'Saving...' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </div >
    );
};


const mapStateToProps = ({ profileUpdate }) => {
    const {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo
    } = profileUpdate
    return {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo
    }
}
const mapDispatchToProps = {
    hideProfileUpdateModal
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo);