import React, { useState } from "react";
import { connect } from 'react-redux'

const SuccessAlert = ({ message, color }) => {
    const [alert, setAlert] = useState(false);

    // const showAlert = () => {
    //     setAlert(true);
    //     setTimeout(() => {
    //         setAlert(false); // Automatically hide the alert after 3 seconds
    //     }, 3000);
    // };

    return (
        <div>
            {/* <button
                onClick={showAlert}
                className="btn btn-primary"
                style={{ marginBottom: "20px" }}
            >
                Success
            </button> */}


            <span
                className={`badge badge-${color}`}
                
            >
                {message}
            </span>

        </div >
    );
};

const mapStateToProps = () => {

    return {}
}

const mapDispatchToProps = {

}



export default connect(mapStateToProps, mapDispatchToProps)(SuccessAlert);
