import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, getRemovedSkills, handelOpenToWork, postUserImage, shortlistApplicant, UserJoinStatus, postUserExperience, updateEducationRecord, updateExperienceRecord } from '../../../../features/constants/apis';
import { hideProfileUpdateModal } from '../../../../features/popup/ProfileUpdateModalSlice';
import { array } from 'prop-types';
import { showAlert } from '../../../../features/utilities/appToastSlice';

// console.log('inside form authToken : ', authToken);
const AddPosition = ({ userInfo, refetchUserData, experience, hideProfileUpdateModal, showAlert }) => {

    console.log('inside experience form: ', userInfo);
    console.log('edit experience Data : ', experience);
    // console.log('inside form authToken : ', authToken);
    const [editabelEducation, setEditableEducation] = useState([])
    const [validated, setValidated] = useState(false);
    const [popUpLoader, setPopUpLoader] = useState(false);
    const [expID, setExpID] = useState(1)
    const [warning, setWarning] = useState('');
    const [jobTitle, setJobTitle] = useState('')
    const [comapnyName, setComapnyName] = useState('')
    const [designation, setDesignation] = useState('')
    const [working, setWorking] = useState(false)
    const [selectedStartYear, setSelectedStartYear] = useState(new Date().getFullYear());
    // const [selectedEndYear, setSelectedEndYear] = useState(new Date().getFullYear());

    const [startMonth, setStartMonth] = useState('')
    const [selectStartYear, setSelecteStartYear] = useState(""); // To manage selected year
    const [endMonth, setEndMonth] = useState('');
    const [selectEndYar, setSelectEndYar] = useState('');
    const [isEditing, setIsEditing] = useState(false);


    const handleWorkingChange = (e) => {
        setWorking(e.target.checked)
        // setSelectedEndYear(e.target.checked && null)
    }
    const startYear = 2000; // Starting year
    const endYear = new Date().getFullYear(); // Current year
    const years = Array.from({ length: endYear - startYear + 1 }, (_, index) => startYear + index);

    // const editPostion = async (e) => {
    //     e.preventDefault()
    //     console.log('editprofile fuc triggerd!!!');
    //     const userData = {
    //         title: jobTitle,
    //         empType: designation,
    //         companyName: comapnyName,
    //         working: working,
    //         startMonth: startMonth,
    //         startYear: selectStartYear,
    //         endMonth: endMonth,
    //         endYear: selectEndYar
    //     }
    //     // const combinedName = `${userData.fName} ${userData.lName}`.trim();
    //     const updateProfile = await UpdateUserProfile(userInfo?.ID, { userData: [userData] }, authToken)
    //     console.log("user Data: ", updateProfile);

    //     // const data = await GetCandidateByID(ID, authToken)
    //     // if (role === 'Candidate') {
    //     //     setUserInfo(data.data);
    //     //     setUserEducation(data.data?.Education);
    //     //     setUserExperience(data.data?.Experience);
    //     // }
    // }



    useEffect(() => {
        if (experience == undefined) {

        }
        else {
            console.log('useEffect called');
            setIsEditing(true)
            setExpID(experience?.expID)
            setJobTitle(experience?.title)
            setComapnyName(experience?.companyName)
            setDesignation(experience?.empType)
            setWorking(experience?.working)
            setStartMonth(experience?.startMonth)
            setSelecteStartYear(experience?.startYear)
            setEndMonth(experience?.endMonth)
            setSelectEndYar(experience?.endYear)
        }
    }, [experience])


    const editPostion = async (e) => {
        console.log('fuc triggerd experience update');
        setPopUpLoader(true);
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
            setValidated(true);
        } else {
            // console.log('form is submitting...')

            const experienceData = {
                'expID': expID,
                'title': jobTitle,
                'empType': designation,
                'companyName': comapnyName,
                'working': working,
                'startMonth': startMonth,
                'startYear': selectStartYear,
                'endMonth': endMonth,
                'endYear': selectEndYar
            }
            // console.log(experienceData)

            if (isEditing) {
                console.log('updating experience...');
                await updateExperienceRecord(authToken, userInfo?.ID, experienceData);

                showAlert({ alertMessage: 'success', alertColor: 'primary', alertVisible: true });
                setTimeout(() => {
                    showAlert({ alertMessage: null, alertColor: null, alertVisible: false });
                }, 3000)
            }
            else {
                console.log('adding experience...');
                await postUserExperience(userInfo?.ID, experienceData, authToken);

                showAlert({ alertMessage: 'success', alertColor: 'primary', alertVisible: true });
                setTimeout(() => {
                    showAlert({ alertMessage: null, alertColor: null, alertVisible: false });
                }, 3000)
            }
            hideProfileUpdateModal();

            // await postUserExperience(userInfo?.ID, experienceData, authToken)



            setPopUpLoader(false);


            // props.onHide()

            // setComapnyName('')
            // setValidated(false);
        }
    }


    console.log('job title: ', jobTitle);
    console.log('company name: ', comapnyName);
    console.log('designation: ', designation);
    console.log('working: ', working);
    // console.log('years: ', years);
    console.log('start month: ', startMonth);
    console.log('start year: ', selectStartYear);
    console.log('end month: ', endMonth);
    console.log('end year: ', selectEndYar);
    const authToken = localStorage.getItem("token"); // Replace "authToken" with your key name
    console.log('authtoken from local storage', authToken); // Logs the token if it exists, or null if it doesn't


    return (
        <div>
            <div class="row">
                <form onSubmit={editPostion}>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="title" style={{ fontSize: '14px', marginBottom: '3px' }}>Title *</label>
                                <input type="text" class="form-control form-control-sm border" id="title" placeholder="Ex. Software Developer [BigTam Technologies]"
                                    value={jobTitle}
                                    onChange={(e) => setJobTitle(e.target.value)}
                                    autocomplete="off" />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="employementType" style={{ fontSize: '14px', marginBottom: '3px' }}>Employement Type</label>
                                <div class="d-flex">
                                    <select class="form-control form-select-sm form-select border" id="employementType"
                                        
                                        value={designation}
                                        onChange={(e) => setDesignation(e.target.value)}>
                                        <option value="Select" disabled>Select</option>
                                        <option value="full-time">Full-Time</option>
                                        <option value="part-time">Part-Time</option>
                                        <option value="contract/temporary">Contract/Temporary</option>
                                        <option value="freelance/independent">Freelance/Independent</option>
                                        <option value="internship">Internship</option>

                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="company" style={{ fontSize: '14px', marginBottom: '3px' }}>Company or Organization *</label>
                                <input type="text" class="form-control form-control-sm border" id="company" placeholder="Ex. Microsoft" autocomplete="off"
                                    value={comapnyName}
                                    onChange={(e) => setComapnyName(e.target.value)} />
                                <i class="clear-input">
                                    <ion-icon name="close-circle"></ion-icon>
                                </i>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-check m-0 me-2 mb-2 mt-2">
                            <input class="form-check-input" type="checkbox" id="currently"
                                value={working}
                                active={working == true}
                                onChange={handleWorkingChange} />
                            <label class="form-checkbox-custom form-check-label ps-3" for="currently">
                                <h6 class="mb-0 text-nowrap fs-6 fw-normal"
                                >I am currently working in this role</h6>
                            </label>
                        </div>
                    </div>
                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="startDate" style={{ fontSize: '14px', marginBottom: '3px' }}>Start Date</label>
                                <div class="d-flex">
                                    <select class="form-control form-select-sm form-select border me-2" id="month"
                                        value={startMonth}
                                        onChange={(e) => setStartMonth(e.target.value)}>
                                        <option value="jan">January</option>
                                        <option value="feb">February</option>
                                        <option value="mar">March</option>
                                        <option value="apr">April</option>
                                        <option value="may">May</option>
                                        <option value="jun">June</option>
                                        <option value="jul">July</option>
                                        <option value="aug">August</option>
                                        <option value="sep">September</option>
                                        <option value="oct">October</option>
                                        <option value="nov">November</option>
                                        <option value="dec">December</option>
                                    </select>
                                    <select class="form-control form-select-sm form-select border" id="year"
                                        value={selectStartYear}
                                        onChange={(e) => setSelecteStartYear(e.target.value)}
                                    >
                                        <option value="0">Year</option>
                                        {years.map((year) => (
                                            <option value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    {working !== true && (<> </>)}

                    <div class="col-12 col-md-12">
                        <div class="form-group boxed">
                            <div class="input-wrapper">
                                <label class="form-label fw-bold" for="startDate" style={{ fontSize: '14px', marginBottom: '3px' }}>End Date (or expected)</label>
                                <div class="d-flex">
                                    <select class="form-control form-select-sm form-select border me-2" id="month"
                                        value={endMonth}
                                        onChange={(e) => setEndMonth(e.target.value)}>
                                        <option value="jan">January</option>
                                        <option value="feb">February</option>
                                        <option value="mar">March</option>
                                        <option value="apr">April</option>
                                        <option value="may">May</option>
                                        <option value="jun">June</option>
                                        <option value="jul">July</option>
                                        <option value="aug">August</option>
                                        <option value="sep">September</option>
                                        <option value="oct">October</option>
                                        <option value="nov">November</option>
                                        <option value="dec">December</option>
                                    </select>
                                    <select class="form-control form-select-sm form-select border" id="year"
                                        value={selectEndYar}
                                        onChange={(e) => setSelectEndYar(e.target.value)}
                                    >
                                        <option value="0">Year</option>
                                        {years.map((year) => (
                                            <option value={year}>{year}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-12 text-end mt-1">
                        <button disabled={popUpLoader} type='submit' class="btn btn-primary">{popUpLoader ? 'Saving...' : 'Save'}</button>
                    </div>
                </form>
            </div>
        </div>
    );
};


const mapStateToProps = ({ profileUpdate }) => {
    const {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo
    } = profileUpdate



    return {
        profileUpdateModalVisible,
        profileUpdateType,
        userInfo,

    }
}
const mapDispatchToProps = {
    hideProfileUpdateModal,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPosition);